import shortid from "shortid";
import { formatAdvancedFilter, formatBasicFilter, getLocalStorageValueByParameter, setLocalStorageValueByParameter } from "../../class/common";
import {
  ALL_REPORTS,
  ALL_WIDGETS,
  API_URL,
  CLIENT_ID_STORAGE,
  ACTION_ID_STORAGE,
  DATASET,
  FISCAL_YEAR,
  HEADER_ELEMENT,
  MANAGE_STACKS,
  MENU_ITEM,
  PERIOD,
  PROFIT_STACK_VIEW,
  PSS,
  SCENARIOS,
  UI_ACTIONS,
  VECTOR_STAGING_ATTRIBUTES,
  logged_in,
  CLIENT_PROFIT_SEGMENT_DICTIONARY,
  SUPPLY_CHAIN_SETUP,
  MAP_COORDINATES
} from "../../class/constants";
import { readCookie, saveCookie } from "../../class/jqueries";
import { COORDINATES_REQUEST_TRACKER, FETCHAPI_PARAMS, FETCH_METHOD, accessNestedProperty, fetchAPI, requestTracker, trackCoordinatesRequest, } from "../../class/networkUtils";
import {
  copyObjectValues,
  findOptionByKey,
  getSectionId,
  findOptionByKeyValue,
  setVersion,
  parseBoolean,
  tryParse,
  convertLocalToEasternTZ,
} from "../../class/utils";
import { lang } from "../../language/messages_en";
import { getSecondDimensionVectors } from "../functions/vectorFunctions";
import cookie from "react-cookies";
import { checkBuiltPeriodAvailability } from "../functions/periodFunctions";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase/firebase";
import { getPastTwelveBuiltPeriods } from "../../class/date";
import { getItemFromStore } from "../../class/reduxStoreUtils";
import { fetchAndFormatFirstLevelPsl } from "../../class/helpers";
import { updatePsLinesOptions } from "../../actions/filterActions";
import { addItem, clearIndexedDB, getAllItemsFromIndexedDB, getItemFromIndexedDB} from "../../indexedDB";


// props can contains idToken, callback, isSwitchingClient, switchingClientFunc
const getUserSettings = (dispatch, updateUserSettings, setUserSettingsState, userSettingsState, profitFormat, sectionId, props, setUserAllowedMenuLinks) => {
  if (Object.keys(userSettingsState)?.length > 0) {
    return;
  }

  // if (isSwitchingClient) {
  //   // clearInterval(this.interval);
  //   if (sessionTimeoutRef) {
  //     sessionTimeoutRef.clearTimeOut();
  //   }
  //   if (this.childRef) {
  //     clearInterval(_this.childRef.interval);
  //   }
  // }
  let query = {
    action: "getUserSettings",
    user_email: readCookie("user_email"),
    section: sectionId,
    url: window.location.href.split("#")[0],
    logging_in: props?.idToken ? true : false,
    domainUrl: window.location.href,
    isSwitchingClient: true,
    client_id: getLocalStorageValueByParameter(CLIENT_ID_STORAGE),
  };
  if (props?.idToken) {
    query.idToken = props?.idToken;
  }

  var onThenCallback = (settingsData) => {
    cookie.remove('fromSignIn', { path: '/' });
    let data = settingsData.settings;
    setLocalStorageValueByParameter(CLIENT_PROFIT_SEGMENT_DICTIONARY, JSON.stringify(data.clientProfitSegmentDictionary));
    if (!data) {
      logout();
      return;
    }
    if (data.userHasNoScreenAccess) {
      setUserAllowedMenuLinks([]);
    }

    if (data.user) {
      data.user["userId"] = data.userId;
    }
    data.clientName = data.name;
    data.machine_name = data.machineName;
    data.records_limit = data.recordsLimit;
    data.showMultipleClientsComponent = false;
    data.format_max_decimal = data.formatMaxDecimal;
    data.session_timeout_ui = data.sessionTimeoutUI;
    data.session_timeout_api = data.sessionTimeoutAPI;
    data.compare_vectors_limit = data.compareVectorsLimit;
    data.clientMFA = parseBoolean(data.mfa);
    data.dashboard_widget_width = Number(data.dashboardWidgetWidth);
    data.dashboard_widget_height = Number(data.dashboardWidgetHeight);
    data.dashboard_grid_columns = Number(data.dashboardGridColumns);
    data.FY = [undefined, "undefined", "null"].includes(readCookie("FY"))
      ? (data.defaultTimeRange === "Q"
        ? "OFF"
        : "ON")
      : readCookie("FY");

    setUserSettingsState(data);
    dispatch(updateUserSettings(data));

    if (props?.callback) {
      props.callback(data.landingPageUrl || "/profit_isle_start");
    }
  };
  var fetchOptions = {
    [FETCHAPI_PARAMS.funcName]: "getUserSettings",
    [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
    [FETCHAPI_PARAMS.showLoader]: true,
    [FETCHAPI_PARAMS.path]: API_URL.USER_SETTINGS,
    [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
    [FETCHAPI_PARAMS.query]: query,
    [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
    [FETCHAPI_PARAMS.profitFormat]: profitFormat,
    [FETCHAPI_PARAMS.useStore]: !props?.isSwitchingClient,
    [FETCHAPI_PARAMS.dataKey]: "userSettings",
    [FETCHAPI_PARAMS.logout]: logout,
  };
  fetchAPI(fetchOptions);
};

const getUserSections = (dispatch, updateUserSections, setUserSectionsState, setUserAllowedMenuLinks, userSectionsState, userSettingsState, profitFormat, isNewMenu, notReadFromStore, callback) => {
  if ((Object.keys(userSettingsState).length === 0)) return;
  if ((userSectionsState?.data?.length > 0 || !userSettingsState.user?.email) && !notReadFromStore) {
    return;
  }
  var query =
    "action=getUserSections" +
    "&user_email=" +
    userSettingsState.user.email +
    "&url=" +
    window.location.href;

  var onThenCallback = (data) => {
    if (dispatch && data) {
      dispatch(updateUserSections([data]));
    }
    if (data.data.length === 0) {
      logout();
    }
    setUserAllowedMenuLinks(data.data);
    setUserSectionsState(data);
    if (callback && typeof callback === 'function') {
      callback();
    }
    // setScenario(isSwitchingClient? undefined : scenario);
  };

  var fetchOptions = {
    [FETCHAPI_PARAMS.funcName]: "getUserSections",
    [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
    [FETCHAPI_PARAMS.showLoader]: true,
    [FETCHAPI_PARAMS.path]: API_URL.ACL,
    [FETCHAPI_PARAMS.method]: FETCH_METHOD.GET,
    [FETCHAPI_PARAMS.query]: query,
    [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
    [FETCHAPI_PARAMS.email]: userSettingsState.user.email,
    [FETCHAPI_PARAMS.machine_name]: userSettingsState.machine_name,
    [FETCHAPI_PARAMS.profitFormat]: profitFormat,
    // [FETCHAPI_PARAMS.useSession]:true,
    [FETCHAPI_PARAMS.useStore]: true,
    [FETCHAPI_PARAMS.dataKey]: "userSectionsData",
    [FETCHAPI_PARAMS.logout]: logout,
  };
  fetchAPI(fetchOptions);
};

const getScenarios = (tempScenarioId, showLoader = true, dispatch, updateScenarioState, setScenarioState, params, scenarioState, accessGroup) => {
  if (scenarioState?.scenarioList?.length > 0) return;
  var query = {
    action: "getScenarios",
    engine: "false",
  };
  query.accessGroupSection = accessGroup ? accessGroup[MENU_ITEM.COLUMNS.MENU_ITEM_MACHINE_NAME] :
    window.location.href
      .replace(window.location.origin, "")
      .replace(params.sectionId, "")
      .split("/")[2] ||
    window.location.href
      .replace(window.location.origin, "")
      .replace(params.sectionId, "")
      .split("/")[1];
  query.tempScenarioId = tempScenarioId;
  var onThenCallback = (data) => {
    if (data && data.scenarios && data.scenarios.length > 0) {
      var scenarios = {};
      var scenarioList = [];
      var defaultScenario = "";
      var tempState = {};
      data.scenarios.map(function (item) {
        if (
          defaultScenario === "" &&
          item[SCENARIOS.F.STATUS] === lang.SCENARIOS.STATUS.PUBLISHED
        ) {
          defaultScenario = item[SCENARIOS.F.ID];
          tempState.scenarioStatus = item[SCENARIOS.F.STATUS];
        }

        if (tempScenarioId) {
          defaultScenario = tempScenarioId;
        }
        scenarios[item[SCENARIOS.F.ID]] = {
          scenario_status: item[SCENARIOS.F.STATUS],
        };
        scenarioList.push({
          value: item[SCENARIOS.F.ID],
          label: item[SCENARIOS.F.NUMBER] + "-" + item[SCENARIOS.F.STATUS],
          [SCENARIOS.F.ID]: item[SCENARIOS.F.ID],
          [SCENARIOS.F.NUMBER]: item[SCENARIOS.F.NUMBER],
          [SCENARIOS.F.STATUS]: item[SCENARIOS.F.STATUS],
          [SCENARIOS.F.NAME]: item[SCENARIOS.F.NAME],
          [SCENARIOS.F.TYPE]: item[SCENARIOS.F.TYPE],
        });
      });
      tempState.scenarioList = scenarioList;
      tempState.scenario = scenarioState?.scenario || defaultScenario;
      tempState.scenarios = [tempState.scenario];
      tempState.scenarioObjects = [
        findOptionByKey(scenarioList, tempState.scenario),
      ];
      tempState.nextScenarios = tempState.scenarios;
      tempState.nextScenarioObjects = tempState.scenarioObjects;
      // _this.handleElementChange(HEADER_ELEMENT.SCENARIO, {value: defaultScenario}, true);
      dispatch(updateScenarioState(tempState));
      setScenarioState(tempState);
    } else {
      setScenarioState(tempState);
    }
  };

  var fetchOptions = {
    [FETCHAPI_PARAMS.funcName]: "getScenarios",
    [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
    [FETCHAPI_PARAMS.showLoader]: showLoader,
    [FETCHAPI_PARAMS.path]: API_URL.OUTPUT,
    [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
    [FETCHAPI_PARAMS.query]: query,
    [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
    [FETCHAPI_PARAMS.useStore]: query.engine === "false",
    [FETCHAPI_PARAMS.dataKey]: "scenarios",
    [FETCHAPI_PARAMS.logout]: logout,
  };
  fetchAPI(fetchOptions);
};

const getPeriodsStatus = (scenarioState, dispatch, updatePeriodsStatusState, setPeriodsStatusState, props, profitFormat, userSettings, rolling) => {
  if (!scenarioState || !scenarioState.scenario) return;
  var tempState = {};
  let isCompareScenarios = profitFormat === ALL_WIDGETS.FIELDS.COMPARE_SCENARIOS
  var query = {
    action: "getPeriodsStatus",
    scenario_id: typeof scenarioState.scenarios[0] === "number" ? scenarioState.scenarios[0] : scenarioState.scenarios[0][SCENARIOS.F.ID],
    rolling: rolling,
    isEngine: false,
    fetchForAllScenarios: isCompareScenarios
  };
  var onThenCallback = (data) => {
    if (dispatch && !isCompareScenarios) {
      dispatch(updatePeriodsStatusState([data], scenarioState.scenario + "_" + rolling));
    }
    if (data.data) {
      if (!isCompareScenarios) {
        var periods = [];
        var endDate = undefined;
        data.data.map(function (item) {
          periods.push({
            value: item[PERIOD.PERIOD_NAME],
            label: item[PERIOD.PERIOD_NAME],
            start_date: item[PERIOD.START_DATE],
            end_date: item[PERIOD.END_DATE],
            period_id: item[PERIOD.PERIDO_ID],
            count: item[PERIOD.COUNT],
            is_built: item[PERIOD.IS_BUILT],
            quarter: item.quarter,
            generated: item.generated,
            rolling: item.rolling,
          });
          if (item[PERIOD.IS_BUILT] && !endDate) {
            //last built period to be selected by default
            endDate = new Date(item[PERIOD.END_DATE]);
          }
        });

        let isFromDashoard = props?.history?.location?.state?.inheritedPeriod;
        let checkForSegmented = [MENU_ITEM.FIELDS.EROSION_AND_GROWTH, MENU_ITEM.FIELDS.PROFIT_LANDSCAPE].includes(profitFormat);

        tempState.builtPeriods = periods;
        // when accessing MoM, never read from cookie unless coming from dashboard
        let nextCustomStartDateCookie = props.additionalProps?.isMoM && !isFromDashoard ? null : cookie.load("nextCustomStartDate");
        let nextCustomEndDateCookie = props.additionalProps?.isMoM && !isFromDashoard ? null : cookie.load("nextCustomEndDate");
        endDate = endDate ? endDate : convertLocalToEasternTZ(new Date(periods[0][PERIOD.END_DATE]));

        if (nextCustomStartDateCookie && nextCustomEndDateCookie) {
          tempState.customStartDate = new Date(nextCustomStartDateCookie.replaceAll('"', ""));
          tempState.customEndDate = new Date(nextCustomEndDateCookie.replaceAll('"', ""));
        } else {
          let startDate = new Date(
            endDate.getFullYear() + "-" + (endDate.getMonth() + 1 < 10 ? "0" : "") + (endDate.getMonth() + 1) + "-01T00:00"
          );
          tempState.customStartDate = startDate;
          tempState.customEndDate = new Date(
            endDate.getFullYear() +
            "-" +
            (endDate.getMonth() + 1 < 10 ? "0" : "") +
            (endDate.getMonth() + 1) +
            "-" +
            ((endDate.getDate() < 10 ? "0" : "") + endDate.getDate()) +
            "T00:00"
          ); // Date format should be YYYY-MM-ddTHH:mm
        }

        // check if the dates are available (built/generated) in the selected scenario
        let startDate = checkBuiltPeriodAvailability(new Date(tempState.customStartDate), periods, checkForSegmented).startDate;
        endDate = checkBuiltPeriodAvailability(new Date(tempState.customEndDate), periods, checkForSegmented).endDate;

        if (new Date(endDate) < new Date(startDate)) {
          endDate = startDate;
        }

        tempState.actuallyBuiltPeriods = tempState.builtPeriods.filter((p) => p[PERIOD.IS_BUILT] === true);
        if (props.additionalProps?.isMoM && !isFromDashoard) {
          tempState.noPeriodInheritance = true;
          tempState.customStartDate = getPastTwelveBuiltPeriods(tempState.actuallyBuiltPeriods) || new Date(startDate);
        } else {
          tempState.customStartDate = new Date(startDate);
          tempState.noPeriodInheritance = false;
        }
        tempState.customEndDate = new Date(endDate);
        tempState.nextCustomStartDate = tempState.customStartDate;
        tempState.nextCustomEndDate = tempState.customEndDate;
        setPeriodsStatusState(tempState);
      } else {
        setPeriodsStatusState(data.data[0]);
      }
    }
  };

  var fetchOptions = {
    [FETCHAPI_PARAMS.funcName]: "getPeriodsStatus",
    [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
    [FETCHAPI_PARAMS.showLoader]: true,
    [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
    [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
    [FETCHAPI_PARAMS.query]: query,
    [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
    // [FETCHAPI_PARAMS.onCompleteCallback]: onCompleteCallback,
    [FETCHAPI_PARAMS.email]: userSettings.user.email,
    [FETCHAPI_PARAMS.machine_name]: userSettings.machine_name,
    [FETCHAPI_PARAMS.profitFormat]: profitFormat,
    [FETCHAPI_PARAMS.dataKey]: "periodsStatus",
    [FETCHAPI_PARAMS.logout]: logout,
    [FETCHAPI_PARAMS.useStore]: !isCompareScenarios,//!_this.isDataModeling &&  [ALL_WIDGETS.FIELDS.LIST, ALL_WIDGETS.FIELDS.HEATMAP.toLowerCase(), ALL_REPORTS.PROFIT_LANDSCAPE.toLowerCase()].includes(_this.state.profitFormat.toLowerCase()),
    [FETCHAPI_PARAMS.scenarioId]: scenarioState.scenarios[0] + "_" + rolling
  };
  let comp = { props: props };
  fetchAPI(fetchOptions, comp);
};

const getClientPeriods = (fromSave, switchAfter, setClientPeriodsState, profitFormat, params, userSettings, getPeriodState, callback, switchCallback) => {
  var tempState = {};
  var query = {
    action: "getClientPeriods",
  };

  var onThenCallback = (data) => {
    if (data) {
      var periods = [];
      var allPeriods = [];
      var periodList = [];
      var periodDetails = [];
      data.periods.map(function (item) {
        allPeriods.push({
          [FISCAL_YEAR.FIELDS.ACTIVE_STATUS]:
            item[FISCAL_YEAR.FIELDS.ACTIVE_STATUS],
          value: item["period_name"],
          label: item["period_name"],
          start_date: item["start_date"],
          end_date: item["end_date"],
          period_id: item["client_period_id"],
          count: item["count"],
          quarter: item["quarter"],
          year: item["year"],
        });
        if (
          item[FISCAL_YEAR.FIELDS.ACTIVE_STATUS] ===
          FISCAL_YEAR.PERIOD_STATUS_VALUES.ACTIVE
        ) {
          periods.push({
            [FISCAL_YEAR.FIELDS.ACTIVE_STATUS]:
              item[FISCAL_YEAR.FIELDS.ACTIVE_STATUS],
            value: item["period_name"],
            label: item["period_name"],
            start_date: item["start_date"],
            end_date: item["end_date"],
            period_id: item["client_period_id"],
            count: item["count"],
            quarter: item["quarter"],
          });
          periodList.push(item["period_name"]);
          periodDetails[item["period_name"]] = {
            start_date: item["start_date"],
            end_date: item["end_date"],
            [FISCAL_YEAR.FIELDS.ACTIVE_STATUS]:
              item[FISCAL_YEAR.FIELDS.ACTIVE_STATUS],
          };
        }
      });
      let periodsForUploadedFiles = periods
        ? periods.filter((e) => e["count"] > 0)
        : [];
      tempState.allPeriods = allPeriods;
      tempState.periodList = periodList;
      tempState.periods = periods; //this state holds the values of all periods regardless of their status
      tempState.selectedPeriod =
        params.report &&
          params.report.toLowerCase() === ALL_WIDGETS.FIELDS.STAGE
          ? periodsForUploadedFiles.length > 0
            ? periodsForUploadedFiles[0]
            : periods[0]
          : periods[0];
      if (
        params.report &&
        params.report.toLowerCase() === ALL_WIDGETS.FIELDS.CONFIGURE
      ) {
        tempState.selectedPeriod = sessionStorage.getItem("selectedPeriod")
          ? sessionStorage.getItem("selectedPeriod")
          : periods[0];
      }
      tempState.periodDetails = periodDetails;
      tempState.fiscalPeriods = data.periods;
      tempState.fiscalOriginalPeriods = copyObjectValues(data.periods);
      tempState.periodsForUploadedFiles = periodsForUploadedFiles;
      //for filling the dropdown, because dataModeling uses a different state
      tempState.periodOptions = periods;
      tempState.nextPeriod = tempState.selectedPeriod;
      if (getPeriodState && typeof getPeriodState === "function") {
        tempState.period = null;
      }

      if (fromSave && callback && typeof callback === 'function') {
        callback(tempState, switchAfter);
      }
      setClientPeriodsState(tempState);
      setLocalStorageValueByParameter("periods", JSON.stringify(periods));

      if (switchCallback && typeof switchCallback === 'function') {
        switchCallback()
      }
    }
  };

  var fetchOptions = {
    [FETCHAPI_PARAMS.funcName]: "getClientPeriods",
    [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
    [FETCHAPI_PARAMS.showLoader]: true,
    [FETCHAPI_PARAMS.path]: API_URL.OUTPUT,
    [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
    [FETCHAPI_PARAMS.query]: query,
    [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
    // [FETCHAPI_PARAMS.onCompleteCallback]: onCompleteCallback,
    [FETCHAPI_PARAMS.email]: userSettings.user.email,
    [FETCHAPI_PARAMS.machine_name]: userSettings.machine_name,
    [FETCHAPI_PARAMS.profitFormat]: profitFormat,
    [FETCHAPI_PARAMS.useSession]: false,
    [FETCHAPI_PARAMS.logout]: logout,
  };
  fetchAPI(fetchOptions);
};

const getVectors = (scenarioState, dispatch, updateTiers, setVectorState, reportTitle, profitFormat, props,
  scenarioChanged, filterFinal, userSettings, vectorState, filterDialRef, report, fetchHiddenVectors) => {
  var tempState = {};
  let isCompareScenarios = profitFormat === ALL_WIDGETS.FIELDS.COMPARE_SCENARIOS
  if (!scenarioState || Object.keys(scenarioState).length === 0) {
    return;
  }
  let query =
    "action=getTiers" +
    "&scenario_id=" +
    (typeof scenarioState.scenarios[0] === "number" || false /*this.isManageScenarios*/
      ? scenarioState.scenarios[0]
      : scenarioState.scenarios[0][SCENARIOS.F.ID])
    + "&fetchHiddenVectors=" + fetchHiddenVectors;
  if (/*this.isManageScenarios*/isCompareScenarios) {
    query += "&fetchForAllScenarios=true";
  }
  let scenario_id =
    typeof scenarioState.scenarios[0] === "number" || false /*this.isManageScenarios*/
      ? scenarioState.scenarios[0]
      : scenarioState.scenarios[0][SCENARIOS.F.ID];
  // let dataFromStore = getItemFromStore("tiers", _this, scenario_id);
  var onThenCallback = (data) => {
    // if(_this.props.dispatch && [ALL_WIDGETS.FIELDS.LIST, ALL_WIDGETS.FIELDS.HEATMAP.toLowerCase(), ALL_REPORTS.PROFIT_LANDSCAPE.toLowerCase()].includes(_this.state.profitFormat.toLowerCase()) && !_this.isDataModeling && !dataFromStore){
    //     _this.props.dispatch(updateTiers([data], scenario_id));
    // }
    let hiddenVectors = data.hiddenVectors;
    if (dispatch && !isCompareScenarios) {
      dispatch(updateTiers([data], scenarioState.scenario));
    }
    data = data.data;
    var allVectors = [];
    var tempGroup = "";
    var group = "";
    var vectorMachineName = "";
    let allScenariosVectors = {};

    if (typeof data === "object" && !Array.isArray(data)) {
      Object.keys(data).forEach((key) => {
        allScenariosVectors[key] = [];
        data[key].map((item) => {
          group = item && item[VECTOR_STAGING_ATTRIBUTES.GROUP] ? item[VECTOR_STAGING_ATTRIBUTES.GROUP] : "";
          vectorMachineName =
            item && item[VECTOR_STAGING_ATTRIBUTES.MACHINE_NAME] ? item[VECTOR_STAGING_ATTRIBUTES.MACHINE_NAME] : "";
          if (group !== tempGroup) {
            tempGroup = group;
            allScenariosVectors[key].push({
              value: tempGroup + " ",
              label: tempGroup + " ",
              className: "tier-group",
              isDisabled: true,
              isGroupTitle: true,
              [VECTOR_STAGING_ATTRIBUTES.GROUP]: group,
              [VECTOR_STAGING_ATTRIBUTES.DISPLAY_ORDER]: item[VECTOR_STAGING_ATTRIBUTES.DISPLAY_ORDER],
            });
          }
          return allScenariosVectors[key].push({
            [VECTOR_STAGING_ATTRIBUTES.IS_DEFAULT]: item[VECTOR_STAGING_ATTRIBUTES.IS_DEFAULT],
            value: vectorMachineName,
            label: item[VECTOR_STAGING_ATTRIBUTES.NAME],
            className: "tier-option",
            [VECTOR_STAGING_ATTRIBUTES.GROUP]: group,
            [VECTOR_STAGING_ATTRIBUTES.DISPLAY_ORDER]: item[VECTOR_STAGING_ATTRIBUTES.DISPLAY_ORDER],
            [VECTOR_STAGING_ATTRIBUTES.DECRIPTION]: item[VECTOR_STAGING_ATTRIBUTES.DECRIPTION],
            [VECTOR_STAGING_ATTRIBUTES.ID]: item[VECTOR_STAGING_ATTRIBUTES.ID],
            entityCount: item.entityCount,
          });
        });
      });

      tempState.allScenariosVectors = allScenariosVectors;
    } else if (Array.isArray(data)) {
      data.map((item) => {
        group = item && item[VECTOR_STAGING_ATTRIBUTES.GROUP] ? item[VECTOR_STAGING_ATTRIBUTES.GROUP] : "";
        vectorMachineName =
          item && item[VECTOR_STAGING_ATTRIBUTES.MACHINE_NAME] ? item[VECTOR_STAGING_ATTRIBUTES.MACHINE_NAME] : "";
        if (group !== tempGroup) {
          tempGroup = group;
          allVectors.push({
            value: tempGroup + " ",
            label: tempGroup + " ",
            className: "tier-group",
            isDisabled: true,
            [VECTOR_STAGING_ATTRIBUTES.GROUP]: group,
            [VECTOR_STAGING_ATTRIBUTES.DISPLAY_ORDER]: item[VECTOR_STAGING_ATTRIBUTES.DISPLAY_ORDER],
            isGroupTitle: true,
          });
        }
        return allVectors.push({
          [VECTOR_STAGING_ATTRIBUTES.IS_DEFAULT]: item[VECTOR_STAGING_ATTRIBUTES.IS_DEFAULT],
          value: vectorMachineName,
          label: item[VECTOR_STAGING_ATTRIBUTES.NAME],
          className: "tier-option",
          [VECTOR_STAGING_ATTRIBUTES.GROUP]: group,
          [VECTOR_STAGING_ATTRIBUTES.DISPLAY_ORDER]: item[VECTOR_STAGING_ATTRIBUTES.DISPLAY_ORDER],
          [VECTOR_STAGING_ATTRIBUTES.DECRIPTION]: item[VECTOR_STAGING_ATTRIBUTES.DECRIPTION],
          [VECTOR_STAGING_ATTRIBUTES.GENERATED]: item[VECTOR_STAGING_ATTRIBUTES.GENERATED],
          [VECTOR_STAGING_ATTRIBUTES.GENERATED_QUARTERS]: item[VECTOR_STAGING_ATTRIBUTES.GENERATED_QUARTERS],
          [VECTOR_STAGING_ATTRIBUTES.ID]: item[VECTOR_STAGING_ATTRIBUTES.ID],
          entityCount: item.entityCount,
          entities: item.entities,
        });
      });
    }
    tempState.vectorOptions = allVectors;
    tempState.originalVectorOptions = allVectors;

    // if(_this.isSecondDimension && _this.props.history.location.state && _this.props.history.location.state.isRedirectionFromStacks) {
    //     //if coming from stacks by range, keep original vectors
    //     tempState.vectors = _this.isSecondDimension && _this.state.scenarioChanged ? this.getSecondDimensionVectors(allVectors) : _this.state.scenarioChanged && !data.length ? [] : _this.state.vectors || [];
    //     tempState.mixVector = this.getSecondDimensionVectors(allVectors)[1];
    //     tempState.originalMixVector = this.getSecondDimensionVectors(allVectors)[1];
    //     tempState.nextMixVector = tempState.mixVector;
    // } else
    if (/*!this.isManageScenarios&&*/  !isCompareScenarios) {
      tempState.mixVector = getSecondDimensionVectors(allVectors)[1];
      tempState.originalMixVector = getSecondDimensionVectors(allVectors)[1];
      tempState.nextMixVector = tempState.mixVector;

      let isHeatmapReport = report === ALL_REPORTS.HEATMAP;
      let isListScreen = report === ALL_REPORTS.LIST_TOP;

      tempState.vectors = isHeatmapReport /*this.isSecondDimension && _this.state.scenarioChanged*/
        ? getSecondDimensionVectors(allVectors)
        : scenarioChanged && !data.length
          ? []
          : vectorState?.vectors || [];

      if (!isHeatmapReport) {
        tempState.vectors[0] = "";
      }
      let vectorFromCookie = cookie.load("vector");
      if (report === ALL_REPORTS.GEOGRAPHY_CONCENTRATION && tempState.vectorOptions.filter((e) => e.vector_group === "Customer").map((v) => { return v["value"]; }).includes(vectorFromCookie)) {
        tempState.vectors[0] = vectorFromCookie;
      } else if (tempState.vectorOptions.map((v) => { return v["value"]; }).includes(vectorFromCookie) && (report !== ALL_REPORTS.GEOGRAPHY_CONCENTRATION && !isHeatmapReport)) {
        tempState.vectors[0] = vectorFromCookie;
      } else if (!isHeatmapReport) {
        tempState.vectors[0] =
          scenarioChanged && data.length > 0 && data[0] && data[0][VECTOR_STAGING_ATTRIBUTES.MACHINE_NAME]
            ? data[0][VECTOR_STAGING_ATTRIBUTES.MACHINE_NAME]
            : scenarioChanged && !data.length
              ? ""
              : tempState.vectors[0] || (data[0] ? data[0][VECTOR_STAGING_ATTRIBUTES.MACHINE_NAME] : "");
        if (!tempState.vectorOptions.map((v) => { return v["value"]; }).includes(tempState.vectors[0])) {
          let tempOpts = tempState.vectorOptions.filter((v) => !v.isDisabled);
          tempState.vectors[0] = tempOpts[0] ? tempOpts[0][VECTOR_STAGING_ATTRIBUTES.MACHINE_NAME] || tempOpts[0].value || "" : "";
        }
      }
      tempState.vectorObjects = tempState.vectors.map((v) => {
        return findOptionByKeyValue(tempState.vectorOptions, "value", v);
      });

      if (props.history?.location?.state?.isRedirectionFromStacks && (isHeatmapReport || isListScreen)) {
        tempState.vectors = props.history.location?.state?.vectors; // when pressing back from entity stacks to go back to heatmap
      }
      if (reportTitle === ALL_REPORTS.PROFIT_LANDSCAPE) {
        let generatedVectors = tempState.vectorOptions?.filter(f => f.generated).length;
        if (generatedVectors > 0) {
          if (!(tempState.vectorOptions.filter(f => f.value === tempState.vectors[0] && f.generated === true).length > 0 && props.history?.location?.state?.isRedirectionFromStacks)) {
            tempState.vectors[0] = tempState.vectorOptions.filter(f => f.generated && !f.isGroupTitle)[0].value;
          }
        }
      }
      tempState.nextVectors = tempState.vectors;
      tempState.vectorObjects = tempState.vectors.map(v => {
        return findOptionByKeyValue(tempState.vectorOptions, "value", v);
      });
      saveCookie("vector", tempState.vectors[0]);
      let nextCustomStartDateCookie = cookie.load("nextCustomStartDate");
      let nextCustomEndDateCookie = cookie.load("nextCustomEndDate");
      if (
        /*!this.isDashboards &&*/ nextCustomStartDateCookie &&
        nextCustomEndDateCookie &&
        reportTitle !== ALL_WIDGETS.FIELDS.TOTAL_STACKS &&
        reportTitle &&
        !reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) &&
        !reportTitle.startsWith(ALL_WIDGETS.FIELDS.COMPARE_STACKS)
      ) {
        // nextCustomStartDateCookie = checkBuiltPeriodAvailability(new Date(nextCustomStartDateCookie), periods, checkForSegmented).startDate;
        // endDate = checkBuiltPeriodAvailability(new Date(nextCustomEndDateCookie), periods, checkForSegmented).endDate;
        // tempState.customStartDate = new Date(startDate);
        // tempState.customEndDate = new Date(endDate);

        saveCookie("nextCustomStartDate", new Date(nextCustomStartDateCookie.replaceAll('"', "")));
        saveCookie("nextCustomEndDate", new Date(nextCustomEndDateCookie.replaceAll('"', "")));
      }
    }

    // if(_this.isSecondDimension && tempState.vectors && tempState.vectors.length > 0 && !(_this.props.history.location.state && _this.props.history.location.state.isRedirectionFromStacks)) {
    //     tempState.numberOfDimensions = 2;
    //     tempState.nextVectors = _this.getSecondDimensionVectors(tempState.vectorOptions);
    //     // _this.sliderRef.handleNumberClick(3);
    //     // _this.props.saveStateForReload({profitFormat: "contourmap"}, false);
    //     tempState.dimension_0 = tempState.nextVectors[0];
    //     tempState.dimension_1 = tempState.nextVectors[1];
    //     tempState.profitFormat = _this.props.profitFormat;
    //     tempState.nextProfitFormat = _this.props.profitFormat;
    //     window._pi_initialiseTracking(UI_ACTIONS.CHANGE_FORMATTING, _this.props.user.email, _this.props.machine_name, "contourmap", false);
    // }
    // if(_this.isDashboards){
    //     let vectors = copyObjectValues(tempState.vectorOptions);
    //     tempState.vectorOptions = vectors
    // }

    let filter = filterFinal && typeof filterFinal === "string" ? JSON.parse(filterFinal) : filterFinal;
    let isAdvancedFilter = filter?.length > 0 && !filter[0].isBasicFilter;

    tempState.exportQueryFilter = isAdvancedFilter
      ? tempState.vectorOptions && filterDialRef?.current?.state?.psLinesOptions
        ? formatAdvancedFilter(
          filter,
          userSettings?.user?.user_allowed_vectors,
          tempState.datasetOptions,
          tempState.vectorOptions,
          filterDialRef?.state?.psLinesOptions
        )
        : undefined
      : formatBasicFilter(
        filter,
        userSettings.user?.user_allowed_vectors
      );
    if (hiddenVectors) {
      tempState.hiddenVectors = hiddenVectors;
    }
    setVectorState(tempState);
  };

  var fetchOptions = {
    [FETCHAPI_PARAMS.funcName]: "fetchVectors",
    [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
    [FETCHAPI_PARAMS.showLoader]: true /*!(_this.props.childRef && _this.props.childRef.state.isSentForReview),*/,
    [FETCHAPI_PARAMS.path]: API_URL.PROFIT_LANDSCAPE,
    [FETCHAPI_PARAMS.method]: FETCH_METHOD.GET,
    [FETCHAPI_PARAMS.query]: query,
    [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
    [FETCHAPI_PARAMS.email]: userSettings?.user?.email,
    [FETCHAPI_PARAMS.machine_name]: userSettings?.machine_name,
    [FETCHAPI_PARAMS.profitFormat]: profitFormat,
    [FETCHAPI_PARAMS.dataKey]: "tiers",
    [FETCHAPI_PARAMS.scenarioId]: scenario_id,
    [FETCHAPI_PARAMS.logout]: logout,
    [FETCHAPI_PARAMS.useStore]: !isCompareScenarios,//!_this.isDataModeling &&  [ALL_WIDGETS.FIELDS.LIST, ALL_WIDGETS.FIELDS.HEATMAP.toLowerCase(), ALL_REPORTS.PROFIT_LANDSCAPE.toLowerCase()].includes(_this.state.profitFormat.toLowerCase()),
    [FETCHAPI_PARAMS.scenarioId]: scenarioState.scenarios[0]
  };
  let comp = { props: props };
  fetchAPI(fetchOptions, comp);
};

const getDatasets = (scenarioState, dispatch, updateDatasetOptions, setDatasetState, props, profitFormat, params, scenarioChanged, userSettings, datasetState) => {
  var tempState = {};
  if (!scenarioState || !scenarioState.scenario) return;
  let query =
    "action=getDataSets" +
    "&isTempScenario=" +
    false + //this.isTempScenario
    "&scenario_id=" +
    (scenarioState.scenarios[0] && isNaN(scenarioState.scenarios[0])
      ? scenarioState.scenarios[0].value
      : scenarioState.scenarios[0]) +
    "&section_id=" +
    getSectionId("PM", params.sectionId);

  // if(this.isManageScenarios || this.isCompareScenarios) {
  //     query += "&fetchForAllScenarios=true";
  // }
  // let dataFromStore = getItemFromStore("datasetOptions",_this, (_this.state.scenarios[0] && isNaN(_this.state.scenarios[0]) ? _this.state.scenarios[0].value : _this.state.scenarios[0]));
  var onThenCallback = (data) => {
    dispatch(updateDatasetOptions([data], scenarioState.scenarios[0]));
    if (data.data) {
      data = data.data;
      if (data.length > 0) {
        tempState.start_date = data[0].start_date;
        tempState.end_date = data[0].end_date;
      }
    }
    var datasets = [];
    var version = "";
    let allScenariosDatasets = {};
    if (typeof data === "object" && !Array.isArray(data)) {
      Object.keys(data).forEach((key) => {
        allScenariosDatasets[key] = [];
        data[key].forEach(function (item) {
          allScenariosDatasets[key].push({
            value: item[DATASET.DATASET],
            label: item[DATASET.DATASET],
            className: "dataSet-option",
            [DATASET.VERSION]: item[DATASET.VERSION],
            name: HEADER_ELEMENT.DATASET,
            [DATASET.START_DATE]: item[DATASET.START_DATE],
            [DATASET.END_DATE]: item[DATASET.END_DATE],
          });
        });
      });

      tempState.allScenariosDatasets = allScenariosDatasets;
    } else if (Array.isArray(data)) {
      data.forEach(function (item) {
        datasets.push({
          value: item[DATASET.DATASET],
          label: item[DATASET.DATASET],
          className: "dataSet-option",
          [DATASET.VERSION]: item[DATASET.VERSION],
          name: HEADER_ELEMENT.DATASET,
          [DATASET.START_DATE]: item[DATASET.START_DATE],
          [DATASET.END_DATE]: item[DATASET.END_DATE],
        });
      });
    }
    let dataSetCookie =
      /*this.props.history.location.state && this.props.history.location.state.dataset ? this.props.history.location.state.dataset : */ cookie.load(
      "dataSet"
    );
    tempState.dataset =
      scenarioChanged || !datasetState?.dataset
        ? data[0]
          ? data[0][DATASET.DATASET]
          : undefined
        : datasetState?.dataset; //if already filled from history, do not change
    if (!findOptionByKeyValue(datasets, "value", dataSetCookie)) {
      tempState.dataset = data[0] ? data[0][DATASET.DATASET] : undefined; //if the dataset chosen is not in the list of datasets take the first one
    } else {
      tempState.dataset = dataSetCookie;
    }

    tempState.dataSet = tempState.dataset;
    var dataset = tempState.dataset;
    version = setVersion(datasets, dataset);
    datasets.unshift({
      value: "quarters",
      label: "Quarters",
      isDisabled: true,
    });

    if (true /*!this.isManageScenarios && !this.isCompareScenarios*/) {
      tempState.nextDataset = tempState.dataset;
      tempState.datasetOptions = datasets;
      tempState.datasetOptions_id = shortid.generate();
      tempState.version = version;
      tempState.startQuarter = datasetState?.startQuarter || tempState.dataset; // change added for when redirecting from dashboard to VA the quarter is set from history
      tempState.endQuarter = datasetState?.endQuarter || datasetState?.dataset;
      tempState.nextStartQuarter = datasetState?.startQuarter
        ? datasetState?.startQuarter
        : cookie.load("nextStartQuarter")
          ? cookie.load("nextStartQuarter")
          : tempState?.dataset;
      tempState.nextEndQuarter = datasetState?.endQuarter
        ? datasetState?.endQuarter
        : cookie.load("nextEndQuarter")
          ? cookie.load("nextEndQuarter")
          : tempState?.dataset;
    }
    setDatasetState(tempState);
    // if(tempState.datasetOptions && this.state.vectorOptions && this.filterDialRef?.state?.psLinesOptions) {
    //     tempState.exportQueryFilter = _this.state.filterFinal === "[]" || _this.state.filterFinal.length === 0 ? undefined : formatAdvancedFilter(JSON.parse(_this.state.filterFinal), this.props?.user?.user_allowed_vectors, tempState.datasetOptions, this.state.vectorOptions, this.filterDialRef?.state?.psLinesOptions);
    // }
  };

  var fetchOptions = {
    [FETCHAPI_PARAMS.funcName]: "fetchDataSets",
    [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
    [FETCHAPI_PARAMS.showLoader]: true,
    [FETCHAPI_PARAMS.path]: API_URL.PROFIT_LANDSCAPE,
    [FETCHAPI_PARAMS.method]: FETCH_METHOD.GET,
    [FETCHAPI_PARAMS.query]: query,
    [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
    // [FETCHAPI_PARAMS.onCompleteCallback]: onCompleteCallback,
    [FETCHAPI_PARAMS.email]: userSettings.user.email,
    [FETCHAPI_PARAMS.machine_name]: userSettings.machine_name,
    [FETCHAPI_PARAMS.profitFormat]: profitFormat,
    [FETCHAPI_PARAMS.dataKey]: "datasetOptions",
    [FETCHAPI_PARAMS.useStore]: true,//!_this.isDataModeling &&  [ALL_WIDGETS.FIELDS.LIST, ALL_WIDGETS.FIELDS.HEATMAP.toLowerCase(), ALL_REPORTS.PROFIT_LANDSCAPE.toLowerCase()].includes(_this.state.profitFormat.toLowerCase()),
    [FETCHAPI_PARAMS.scenarioId]: scenarioState.scenarios[0]
  };
  let comp = { props: props };
  fetchAPI(fetchOptions, comp);
};

const getFilterEntities = (item, vectorState, setBasicFilterEntities, basicFilterEntitiesState, profitFormat, userSettings, scenarioState, filterFinal, getCountRef, setCount, setCountRef) => {
  var tier = item.value;
  var field = "key"; //item.value;

  var tierOption = vectorState.vectorOptions?.filter((e) => e.value === tier);
  let vectorId =
    tierOption?.length > 0
      ? tierOption[0][VECTOR_STAGING_ATTRIBUTES.ID]
      : undefined;
  var isDefault =
    tierOption?.length > 0
      ? tierOption[0][VECTOR_STAGING_ATTRIBUTES.IS_DEFAULT]
      : false;
  let scenarioId = typeof scenarioState.scenario === "number" ? scenarioState.scenario : scenarioState.scenario.value;
  var query =
    "action=getTierFilter" +
    "&tier=" +
    tier +
    "&scenario_id=" +
    scenarioId +
    "&filter_value=" +
    encodeURIComponent("") +
    "&filter_attribute=" +
    field +
    "&is_default=" +
    isDefault +
    "&vector_id=" +
    vectorId +
    "&isBasicFilter=" +
    true;

  var onThenCallback = (data) => {
    let tempState = {};
    data = data && data.length ? data : [];
    let basicFilterEntities = basicFilterEntitiesState || [];
    let filterFinalArr = tryParse(filterFinal);
    // filterFinalArr =filterFinalArr
    let checkedNumbers = filterFinalArr
      ?.filter((e) => e.vector === tier && e.field === "number")[0]
      ?.entities?.map((val) => {
        return val.number;
      });
    let checkedNames = filterFinalArr
      ?.filter((e) => e.vector === tier && e.field === "name")[0]
      ?.entities?.map((val) => {
        return val.name;
      });
    data.map((item) => {
      item.checked =
        checkedNumbers?.includes(item.number) &&
        checkedNames?.includes(item.name);
    });

    basicFilterEntities[tier] = data;
    setBasicFilterEntities(basicFilterEntities);
    let countEntitiesstate = getCountRef();
    setCount(countEntitiesstate + 1);
    setCountRef(countEntitiesstate + 1);
  };

  var fetchOptions = {
    [FETCHAPI_PARAMS.funcName]: UI_ACTIONS.FILTER_AUTOCOMPLETE,
    [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
    [FETCHAPI_PARAMS.showLoader]: false,
    [FETCHAPI_PARAMS.path]: API_URL.PROFIT_LANDSCAPE,
    [FETCHAPI_PARAMS.method]: FETCH_METHOD.GET,
    [FETCHAPI_PARAMS.query]: query,
    [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
    [FETCHAPI_PARAMS.machine_name]: userSettings.machine_name,
    [FETCHAPI_PARAMS.profitFormat]: profitFormat,
  };
  //using a UI action and not function name because this action starts here and ends here, unlike change_format and select_filter
  if (vectorId && vectorId !== null && tierOption && tierOption.length > 0) {
    fetchAPI(fetchOptions);
  }
};

const getCustomViewData = (PSViewsState, vectorState, params, filterFinal, userSettings, scenarioState, dispatch, updatePsViews, setPSViewsState, scenarioChanged, props) => {
  if (!scenarioState.scenario) return;
  var tempState = {};
  var query = {
    user_email: userSettings.user.email,
    url: window.location.href,
    section_id: params.sectionId,
    action: "listProfitStackViews",
    filter: '{"filter":' + encodeURIComponent(filterFinal?.length > 0 ? filterFinal : "[]") + "}",
    vector: vectorState.vector,
    scenario_id: scenarioState.scenario,
    allowed: true,
  };
  let scenario_id = scenarioChanged.scenario;
  var onThenCallback = (data) => {
    dispatch(updatePsViews([data], scenarioState.scenario));
    if (data.error) {
      if (data.error.level === 3) {
        data = { can_edit: false, data: [] };
      }
    }
    tempState.views_data = data;
    tempState.views_data.id = shortid.generate();
    tempState.canEdit = data.can_edit;
    tempState.profitStackViews = [
      {
        value: 0,
        label: "Default Profit Stack",
        tooltipText: "",
        is_valid: true,
      },
    ];

    let disabledViewMsg = null;
    if (!!scenarioState.scenarioObjects && scenarioState.scenarioObjects[0]) {
      disabledViewMsg = lang.disabled_custom_view;
    }

    for (var i = 0; i < tempState.views_data.data.length; i++) {
      let isValid = parseBoolean(
        tempState.views_data.data[i][PROFIT_STACK_VIEW.IS_VALID]
      );
      tempState.profitStackViews.push({
        value: tempState.views_data.data[i]["custom_stack_id"],
        label: tempState.views_data.data[i][PROFIT_STACK_VIEW.NAME],
        tooltipText: isValid
          ? tempState.views_data.data[i][PROFIT_STACK_VIEW.DESCRIPTION]
          : disabledViewMsg,
        is_valid: isValid,
        isDisabled: !isValid,
        saved_filter_id:
          tempState.views_data.data[i][PROFIT_STACK_VIEW.FILTER_ID],
        visibility:
          tempState.views_data.data[i][MANAGE_STACKS.FIELDS.VISIBILITY],
      });
    }
    // if we are changing the selected stack to the default one because the selected stack is not compatible with the selected scenario
    if (
      PSViewsState?.nextProfitStackViewId && PSViewsState.nextProfitStackViewId !== 0 &&
      tempState?.profitStackViews.filter(
        (e) => e.value === PSViewsState?.nextProfitStackViewId
      ).length === 0
    ) {
      tempState.nextProfitStackViewId = 0;
      tempState.shouldShowWarning = true;
    } else {
      tempState.shouldShowWarning = false;
    }
    setPSViewsState(tempState);
  };

  var fetchOptions = {
    [FETCHAPI_PARAMS.funcName]: "loadCustomViewData",
    [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
    [FETCHAPI_PARAMS.showLoader]: true,
    [FETCHAPI_PARAMS.path]: API_URL.MANAGE_STACKS,
    [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
    [FETCHAPI_PARAMS.query]: query,
    [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
    [FETCHAPI_PARAMS.useSession]: false,
    [FETCHAPI_PARAMS.dataKey]: "customViewData",
    [FETCHAPI_PARAMS.scenarioId]: scenario_id,
    [FETCHAPI_PARAMS.useStore]: true,//!_this.isDataModeling &&  [ALL_WIDGETS.FIELDS.LIST, ALL_WIDGETS.FIELDS.HEATMAP.toLowerCase(), ALL_REPORTS.PROFIT_LANDSCAPE.toLowerCase()].includes(_this.state.profitFormat.toLowerCase()),
    [FETCHAPI_PARAMS.scenarioId]: scenarioState.scenarios[0]
  };
  let comp = { props: props };
  fetchAPI(fetchOptions, comp);
};

const updateLastLoginDate = (email) => {
  let query = {
    action: "updateLastLoginDate",
    email: email
  }

  let onThenCallback = function (data) {
    if (!data.success) {
      console.log("Could not update last login date")
    }
  }

  let options = {
    [FETCHAPI_PARAMS.funcName]: "updateLastLoginDate",
    [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
    [FETCHAPI_PARAMS.credentials]: FETCHAPI_PARAMS.credentialsValues.include,
    [FETCHAPI_PARAMS.showLoader]: false,
    [FETCHAPI_PARAMS.path]: API_URL.USER,
    [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
    [FETCHAPI_PARAMS.query]: query,
    [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
  };

  fetchAPI(options, false);
}

var getBridgeOptions = function (scenarioState, dispatch, updateBridgeOptionsState, bridgeOptions, setBridgeOptions) {
  if (!scenarioState.scenario) return;
  if (!!bridgeOptions) return;

  let tempState = {};
  // let tooltipText = "";
  let query = {
    action: "getBridgeOptions",
    scenario_id: scenarioState.scenario,
  };

  let onThenCallback = (data) => {
    if (data) {

      let bridgeData = data.reports;
      let config = data.quadrantConfig;
      let pss_fields = tryParse(data.pss_fields);
      let calculated_lines = pss_fields.filter((e) => e.costtype === "calculated" && e.format_type_id !== "5");
      var options = [];
      bridgeData.map((item) => {
        options.push({ label: item[PSS.NAME], value: item[PSS.RETURN_NAME], report_id: item.report_id, custom_view_id: item.custom_stack_id });
      });
      // tooltipText = lang.bridge_configurations.no_bridge_configurations;

      if (options.length > 0) {
        let newBridgeOptions = [];
        for (var i in options) {
          if (calculated_lines.filter((e) => e.returnName === options[i].value).length > 0) {
            //check if configured calculated lines are compatible with selected scenario
            newBridgeOptions.push(options[i]);
          }
        }
        if (newBridgeOptions.length > 0) {
          tempState.bridgeOptions = newBridgeOptions;
          let bridgeObj =
            config.length > 0 && newBridgeOptions.length > 0 && newBridgeOptions.filter((e) => e.value === config[0][PSS.RETURN_NAME]).length > 0
              ? newBridgeOptions.filter((e) => e.value === config[0][PSS.RETURN_NAME])[0]
              : newBridgeOptions.length > 0
                ? newBridgeOptions[0]
                : undefined;
          tempState.bridge = bridgeObj.value;
          tempState.nextBridgeObj = bridgeObj;
          tempState.bridgeCustomViewId = bridgeObj.custom_view_id;
          tempState.originalBridge = bridgeObj.value;
          tempState.nextBridgeCustomViewId = bridgeObj.custom_view_id;
          tempState.nextSecondaryReport = bridgeObj.report_id;
          tempState.secondaryReport = bridgeObj.report_id;
          tempState.nextBridge = tempState.bridge;
        }
      }
      setBridgeOptions(tempState);
      dispatch(updateBridgeOptionsState([tempState], scenarioState.scenario));

    }
  };

  let fetchOptions = {
    [FETCHAPI_PARAMS.funcName]: "getBridgeOptions",
    [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
    [FETCHAPI_PARAMS.showLoader]: true,
    [FETCHAPI_PARAMS.path]: API_URL.PROFIT_VECTORS,
    [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
    [FETCHAPI_PARAMS.query]: query,
    [FETCHAPI_PARAMS.dataKey]: "bridgeOptions",
    [FETCHAPI_PARAMS.scenarioId]: scenarioState.scenarios[0],
    [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
  };

  fetchAPI(fetchOptions);
};

const logout = () => {
  localStorage.removeItem('saved_filter_id');
  localStorage.removeItem('saved_filter');
  sessionStorage.removeItem(logged_in);
  clearIndexedDB();
  setLocalStorageValueByParameter(COORDINATES_REQUEST_TRACKER, JSON.stringify([]));
  // removeAllCookies(['filterBox','filterFinal','isDrilling','tempScenarioId']);

  setLocalStorageValueByParameter(CLIENT_ID_STORAGE, "");
  setLocalStorageValueByParameter(ACTION_ID_STORAGE, "");
  const query = {
    "action": "logout",
  }

  var onCompleteCallback = (data) => {
    signOut(auth).then(() => {
      sessionStorage.clear();		//clearing session storage from all the saved data
      window.location.href = process.env.REACT_APP_CLIENT_BASE_URL;
    }).catch(function (error) {
      console.log("error: " + error);
    });
  }

  var fetchOptions = {
    [FETCHAPI_PARAMS.funcName]: "logout",
    [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
    [FETCHAPI_PARAMS.showLoader]: false,
    [FETCHAPI_PARAMS.path]: API_URL.USER,
    [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
    [FETCHAPI_PARAMS.query]: query,
    [FETCHAPI_PARAMS.onCompleteCallback]: onCompleteCallback,
  };

  fetchAPI(fetchOptions);
}

const updateShowAgain = (userSettingsState, showAgain) => {
  let query = {
    action: "updateShowAgain",
    showAgain: showAgain,
    email: userSettingsState.user.email
  }
  let onThenCallback = (data) => {

  }
  let fetchOptions = {
    [FETCHAPI_PARAMS.funcName]: "updateShowAgain",
    [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
    [FETCHAPI_PARAMS.showLoader]: false,
    [FETCHAPI_PARAMS.path]: API_URL.USER,
    [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
    [FETCHAPI_PARAMS.query]: query,
    [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
  };
  fetchAPI(fetchOptions);
}

const getImagesSignedUrls = (setSignedImagesUrls, dispatch, updateImagesSignedUrls, imagesSignedUrls) => {
  if (imagesSignedUrls?.length > 0) {
    return;
  }
  let query = {
    action: "getImagesSignedUrls",
  };

  var onThenCallback = (data) => {
    setSignedImagesUrls(data);
    dispatch(updateImagesSignedUrls(data));
  };
  var fetchOptions = {
    [FETCHAPI_PARAMS.funcName]: "getImagesSignedUrls",
    [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
    [FETCHAPI_PARAMS.showLoader]: false,
    [FETCHAPI_PARAMS.path]: API_URL.USER_SETTINGS,
    [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
    [FETCHAPI_PARAMS.query]: query,
    [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
  };
  fetchAPI(fetchOptions);
};


const getProfitStackHierarchy = (viewId, scenario, comp, setPSLDataCallback) => {
  if (!scenario) {
    return;
  }
  let query = {
    action: "getCostHierarchy",
    scenario_id: scenario,
    view_id: viewId,
    comparisonScenarioId: comp?.props?.comparisonScenarioId
  }

  let tempState = {};
  let onThenCallback = (data) => {
    let pslObject = {};
    let psLinesOptions = getItemFromStore("psLinesOptions", comp, scenario);
    if (data.firstLevelPsl) {
      fetchAndFormatFirstLevelPsl(data.firstLevelPsl, pslObject)
      if (comp.props.dispatch && scenario) {
        comp.props.dispatch(updatePsLinesOptions([pslObject.psLines], scenario));
      }
    } else if (psLinesOptions?.length > 0) {
      pslObject.psLines = psLinesOptions;
    }
    tempState.psLinesOptions = pslObject.psLines;
    if (comp.state?.datasetOptions && comp.state?.vectorOptions && tempState.psLinesOptions) {
      tempState.exportQueryFilter = comp.state.filterFinal === "[]" ? undefined : formatAdvancedFilter(comp.state.filterFinal, comp.props?.user?.user_allowed_vectors, comp.state?.datasetOptions, comp.state?.vectorOptions, tempState.psLinesOptions);
    }

    if (comp?.props?.filterInDashBoard) {
      comp?.setState({// I had to put this setState to fix a bug
        psLinesOptions: pslObject.psLines
      })
    }
    if (typeof comp?.awaitOtherLists === "function") {
      comp.awaitOtherLists("getProfitStackHierarchy", tempState);
    }
    if (typeof setPSLDataCallback === "function") {
      setPSLDataCallback(pslObject.psLines, data.firstLevelPsl);
    }
  }


  let fetchOptions = {
    [FETCHAPI_PARAMS.funcName]: "getCostHierarchy",
    [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
    [FETCHAPI_PARAMS.showLoader]: true,
    [FETCHAPI_PARAMS.path]: API_URL.PROFIT_LANDSCAPE,
    [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
    [FETCHAPI_PARAMS.query]: query,
    [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
    [FETCHAPI_PARAMS.useStore]: !comp?.props?.comparisonScenarioId,
    [FETCHAPI_PARAMS.dataKey]: "psLinesOptions",
    [FETCHAPI_PARAMS.scenarioId]: scenario,
  }
  fetchAPI(fetchOptions, comp);
}

const fetchConfiguration = (vectorsOnly, setConfigurationData, validateConfigurationFields) => {
  let query = {
    action: "fetchConfiguration",
    vectorsOnly: vectorsOnly || false
  };

  const onThenCallback = (data) => {
    if (data?.configuration) {
      setConfigurationData(data.configuration);
      if (validateConfigurationFields && typeof validateConfigurationFields === 'function') {
        validateConfigurationFields(data.configuration);
      }
    } else {
      setConfigurationData({ exclusionConfiguration: [], vectorsConfiguration: [], bucketsConfiguration: [] });
    }
  };

  const fetchOptions = {
    [FETCHAPI_PARAMS.funcName]: "fetchConfiguration",
    [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
    [FETCHAPI_PARAMS.showLoader]: true,
    [FETCHAPI_PARAMS.path]: SUPPLY_CHAIN_SETUP.API_URLS.FETCH_CONFIGURATION,
    [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
    [FETCHAPI_PARAMS.query]: query,
    [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
  };

  fetchAPI(fetchOptions);
};

function getProfitStackLines(scenarioId, removeAttributes, callback, showCustomizedLoader = false, section) {
  var query = {
    action: "getProfitStackFields",
    scenario_id: scenarioId,
    removeAttributes: removeAttributes ? removeAttributes : "false"
  }

  var onThenCallback = (data) => {
    if (data) {
      callback(data);
    }
  }

  var fetchOptions = {
    [FETCHAPI_PARAMS.funcName]: "getProfitStackLines",
    [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
    [FETCHAPI_PARAMS.showLoader]: true,
    [FETCHAPI_PARAMS.showCustomizedLoader]: showCustomizedLoader,
    [FETCHAPI_PARAMS.path]: API_URL.OUTPUT,
    [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
    [FETCHAPI_PARAMS.query]: query,
    [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
    [FETCHAPI_PARAMS.screenName]: accessNestedProperty(section, lang.observability)?.screen_name,
    [FETCHAPI_PARAMS.requestDescription]: accessNestedProperty(section, lang.observability)?.requests_description?.get_profit_stack_fields,
  }
  fetchAPI(fetchOptions);
}

/**
 * function sends request to fetch US State, zipcodes files but first checks if the files exist in the indexed DB and checks 
 * if a request is already sent for the file, if both conditions are satisfied then therequest is sent and trackCoordinates is called to log that the request is sent
 */
function getMapCoordinates() {
  let query = {
    action: "getMapCoordinates"
  }

  let onThenCallback = (data, options) => {
      trackCoordinatesRequest(options, true);
      delete data.logDates;
      addItem({name:data, id: options[FETCHAPI_PARAMS.index]});
  }
 
  let fetchOptions = {
    [FETCHAPI_PARAMS.funcName]: "getMapCoordinates",
    [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
    [FETCHAPI_PARAMS.showLoader]: false,
    [FETCHAPI_PARAMS.path]: API_URL.USER_SETTINGS,
    [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
    [FETCHAPI_PARAMS.query]: query,
    [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
    [FETCHAPI_PARAMS.index]:  MAP_COORDINATES.US_STATES,
    [FETCHAPI_PARAMS.useIndexedDB]: true

  }
  query.index = MAP_COORDINATES.US_STATES;

  var getItems = async () => {
    let allItems = await getAllItemsFromIndexedDB();
    let coordinatesRequestracker = tryParse(getLocalStorageValueByParameter(COORDINATES_REQUEST_TRACKER)) || [];
    if (allItems.filter(e=>e.id === query.index).length === 0 && coordinatesRequestracker.filter(e=>e === query.index).length === 0) {
      trackCoordinatesRequest(fetchOptions);
      fetchAPI(fetchOptions);
    }
    for (var i=1; i<=6; i++) {
      var query2 = copyObjectValues(query);
      var fetchOptions2 = copyObjectValues(fetchOptions);
      query2.index = MAP_COORDINATES.ZIPCODES+i;
      fetchOptions2[FETCHAPI_PARAMS.query] = query2;
      fetchOptions2[FETCHAPI_PARAMS.index] = MAP_COORDINATES.ZIPCODES+i;
      fetchOptions2[FETCHAPI_PARAMS.onThenCallback] = onThenCallback;
      let coordinatesRequestracker = tryParse(getLocalStorageValueByParameter(COORDINATES_REQUEST_TRACKER)) || [];
      if (allItems.filter(e=>e.id === query2.index).length === 0 && coordinatesRequestracker.filter(e=>e === query2.index).length === 0) {
        trackCoordinatesRequest(fetchOptions2);
        fetchAPI(fetchOptions2);
      }
    }
  }
  getItems();
}

export {
  getScenarios,
  getPeriodsStatus,
  getClientPeriods,
  getVectors,
  getDatasets,
  getFilterEntities,
  getCustomViewData,
  getUserSections,
  getUserSettings,
  logout,
  updateLastLoginDate,
  getBridgeOptions,
  updateShowAgain,
  getImagesSignedUrls,
  getProfitStackHierarchy,
  fetchConfiguration,
  getProfitStackLines,
  getMapCoordinates
}
