//This is the second sub component
import React, { Component } from 'react';
import Tabulator from "tabulator-tables";

import { getReadableCostTerm, getReadableFilterTextPSLDefinition, getReadableRuleText, getReadableRuleTextPSLDefinition, setLocalStorageValueByParameter, toggleLoader } from '../class/common.js';
import { ALL_WIDGETS, API_URL, COST_FUNCTIONS_FIELDS, PS_MAPPING, VECTOR_ANALYSIS, costtype } from '../class/constants.js';
import { convertPxToViewport } from '../class/formatting';
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI, fetchAPIAjax } from '../class/networkUtils.js';
import { exportToExcel, tabulatorExport } from '../class/tabulatorExport.js';
import { capitalizeFirstLetter, tryParse } from '../class/utils.js';
import { getExpandCollapseButtons } from '../newComponents/tabulatorComponents';
import { lang } from '../language/messages_en';
const _formula = "Formula: ";
/**
 * Profit Stack Line Definitions table
 * @author [Sarah Farjallah]
 */
const _children = PS_MAPPING.FIELDS.CHILDREN;
const $ = require("jquery");

const baseUrl = process.env.REACT_APP_BASE_URL;
const _yes = "Yes";
const _no = "No";
const _all = "ALL";
const NA = "N/A";
const PSL_DEFINITIONS_RETURN_NAME = ALL_WIDGETS.FIELDS.PROFIT_STACK_LINE_DEFINITIONS;
const no_description = "No Description";
const _hidden = " (Hidden)";
const EXPAND_ELEMENT =
{ 
    title: '', 
    field: PS_MAPPING.FIELDS.EXPAND,
    headerSort: false,
    dontFilter: true,
    cssClass: "expand-collapse-cell",
    width: '5%',
    download: false
}

class ProfitStackLineDefinitionsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterFinal: "[]",
            columns: [],
            level:0,
            lowestLevel:0,
            expanded: false,
            period:"",
            cost_center: ""
        }
        this.fetchAPI = fetchAPI.bind(this);
        this.setColumns = this.setColumns.bind(this);
        this.getTabulator = this.getTabulator.bind(this);
        this.expandAll = this.expandAll.bind(this);
        this.collapseAll = this.collapseAll.bind(this);
        this.exportTableToExcel = this.exportTableToExcel.bind(this);
        this.getColumnFormatter = this.getColumnFormatter.bind(this);
        this.checkAllTree = this.checkAllTree.bind(this);
        this.linearize = this.linearize.bind(this);
        this.appendExportUnderScore = this.appendExportUnderScore.bind(this);
        this.getClientCostTerms = this.getClientCostTerms.bind(this);
        this.arrangePeriods = this.arrangePeriods.bind(this);
        this.displayPeriods = this.displayPeriods.bind(this);
        this.getPSLDefinitonsData = this.getPSLDefinitonsData.bind(this);
        this.setPage = this.setPage.bind(this);
        this.handlePeriodChange = this.handlePeriodChange.bind(this);
        // this.closeModal = this.closeModal.bind(this);
    }
    
    getTabulator() {
        if(this.tabulator)
            return this.tabulator;
        return null;
    }

    // addNewRowTitleFormatter(cell, params) {
    //     let div = document.createElement("div");
    //     let a = document.createElement('a');
    //     a.innerHTML = params.title; 
    //     a.classList.add('uk-cursor-pointer', 'branchState');
    //     a.onclick = function(){
    //         window._profitIsleOpenModal("branchStateModal");
    //         // obj.setPage(0);
    //     }
    //     div.appendChild(a);
    //     return div;
    // }

    arrangePeriods(periods) {
        periods = periods.reverse();
        var periodMessageArr = [];
        for (var elt in periods) {
            if (Number(elt) === 0) {
                periodMessageArr.push(periods[elt].value);
            }else{
                // previous period is equal to current period +1
                if (periods[elt].value.split("P")[0] === periods[Number(elt)-1].value.split("P")[0] && Number(periods[Number(elt)-1].value.split("P")[1]) + 1 === Number(periods[Number(elt)].value.split("P")[1])) {
                    if (periodMessageArr[periodMessageArr.length-1] !== "➜") { //if no arrow add arrow to show consecutive periods
                        periodMessageArr.push("➜");
                    }
                    if (Number(elt) === periods.length -1) { // if reached end of array of periods we add last period to look like this p1 ➜ p12
                        periodMessageArr.push(periods[Number(elt)].value);
                    }
                    // if previous year is last period of year and current period is first period of the next year then they are still consecutive ex: 2019p12 and 2020P01
                } else if (Number(periods[elt].value.split("P")[0]) - 1  === Number(periods[Number(elt)-1].value.split("P")[0]) && (Number(periods[Number(elt)-1].value.split("P")[1])  ===  12 || Number(periods[Number(elt)-1].value.split("P")[1])  ===  13) &&  Number(periods[Number(elt)].value.split("P")[1]) === 1){
                    if (periodMessageArr[periodMessageArr.length-1] !== "➜") { //add arrow to show pweriods are still consective
                        periodMessageArr.push("➜");
                        if (Number(elt) === periods.length -1) {// if reached end of array of periods we add last period to look like this p1 ➜ p12
                            periodMessageArr.push(periods[Number(elt)].value);
                        }
                    }
                } else if (periodMessageArr[periodMessageArr.length-1] === "➜") {// no more consecutive periods code will reach here and check if last item was ➜ then it adds previous period and appends a , to distinguish between stand alone periods and condesctive ones ex: 2019p8,2019p9,201p10p10,2020p1 it should be 2019P8->P10, 2020P1
                    periodMessageArr.push(periods[Number(elt)-1].value);
                    periodMessageArr.push(", \n");
                    periodMessageArr.push(periods[Number(elt)].value);
                }else { // no more consecutive periods and last item index is not ➜ then it was a stand alone period so we add, and append the current period
                    periodMessageArr.push(", \n")
                    periodMessageArr.push(periods[Number(elt)].value)
                }
            }
        }
        return periodMessageArr.join(" ");
    }

    periodExist(period, config) {
        var found = false;
        for (var e in config) {
            if (!config[e][COST_FUNCTIONS_FIELDS.IS_DEFAULT]) {
                if (config[e].periods.includes(period)) {
                    found = true
                }
            }
        }
        return !found;
    }

    onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }

    displayPeriods(config, is_default) {
        var periods = this.props.periods;
        var toBeDisplayedPeriods = []
        if (!is_default) {
            var uniquePeriods = [];
            for (var i in config.periods) {
                if (!uniquePeriods.includes(config.periods[i])) {
                    uniquePeriods.push(config.periods[i]);
                }
            }
            for (var e in uniquePeriods){
                if (periods.filter(elt=>elt.value === uniquePeriods[e]).length > 0){   
                    toBeDisplayedPeriods.push({value:uniquePeriods[e]})
                }
            }
            return this.arrangePeriods(toBeDisplayedPeriods);
        } else {
            for (var e in periods) {
                var found = false;
                for (var elt in config) {
                    if (config[elt].periods.includes(periods[e].value)) {
                        found = true;
                    }
                }
                if (!found) {
                    toBeDisplayedPeriods.push(periods[e]);
                }
            }
            return  this.arrangePeriods(toBeDisplayedPeriods);
        }
    }
    
    getColumnAccessor(colField) {
        var columnFormatter = "";
        var obj = this;
        switch(colField) {
            case PS_MAPPING.FIELDS.NAME:
                columnFormatter = function(value, data, type, params, colCompnent, rowComponent) {
                    var rowData = data;
                    let text = (!rowData[_children] || rowData[_children].length === 0 )&& (!rowData[COST_FUNCTIONS_FIELDS.SHOW_CHILDREN] && rowData[COST_FUNCTIONS_FIELDS.COST_TYPE].toLowerCase() !== costtype.calculated && rowData[COST_FUNCTIONS_FIELDS.COST_TYPE].toLowerCase() !== costtype.attribute) && rowData['level'] !== 1 ? value + _hidden : value;
                    return obj.appendExportUnderScore(text, rowData.level);
                }
                break;

            case PS_MAPPING.FIELDS.COST_TERM:
                columnFormatter = function(value, data, type, params, colCompnent, rowComponent) {
                    var rowData = data;
                    if (!rowData[_children] || rowData[_children].length === 0 ) {
                        var label = "";
                        var options = obj.state.clientCostTerms;
                        if (rowData[PS_MAPPING.FIELDS.COST_TERM] && rowData[PS_MAPPING.FIELDS.COST_TERM] !== "") {
                            label = options.filter(e=>e.cost_term_id.toString() === rowData[PS_MAPPING.FIELDS.COST_TERM].toString())[0].value;
                        } else{
                            label = options[0].label;
                        }

                        if (rowData[COST_FUNCTIONS_FIELDS.COST_TYPE].toLowerCase() === costtype.calculated || rowData[COST_FUNCTIONS_FIELDS.COST_TYPE].toLowerCase() === costtype.attribute) {
                            let textContent = rowData[COST_FUNCTIONS_FIELDS.COST_TYPE].toLowerCase() === costtype.calculated ? "" : _formula;
                            var attrFormula = rowData[PS_MAPPING.FIELDS.FORMULA] ? rowData[PS_MAPPING.FIELDS.FORMULA].replace(/AC/g,'').replace(')','').replace(/f\./g,'').replace("(", "  Of ").toLowerCase() :"";
                            attrFormula = attrFormula.replace("key","").replace("_cc","");
                            var attrFormulaArr = attrFormula.split(" ");
                            var attrFormuaDisplay = [];
                            for(var e in attrFormulaArr) {
                                attrFormuaDisplay.push(capitalizeFirstLetter(attrFormulaArr[e]));
                            }
                            textContent += rowData[COST_FUNCTIONS_FIELDS.COST_TYPE].toLowerCase() === costtype.calculated ? "": attrFormuaDisplay.join(" ").replace('Distinct','distinct').replace('Of','of');
                            return textContent;
                        }else{
                            return capitalizeFirstLetter(label);
                        }
                    }
                    return "";
                }
            break;

            case COST_FUNCTIONS_FIELDS.COST_TYPE:
                columnFormatter = function(value, data, type, params, colCompnent, rowComponent) {
                    let rowData = data;
                    if (!rowData[_children] || rowData[_children].length === 0) {
                        return capitalizeFirstLetter(value.replace(costtype.invoicelinetype,costtype.standard));
                    }
                    return "";
                }
            break;
                
            case COST_FUNCTIONS_FIELDS.COST_CENTER:
                columnFormatter = function(value, data, type, params, colCompnent, rowComponent) {
                    let rowData = data;
                    let textContent = value !== _all ? _yes : _no;
                    if (!rowData[_children] || rowData[_children].length === 0) {
                        if (rowData[COST_FUNCTIONS_FIELDS.COST_TYPE].toLowerCase() === costtype.calculated || rowData[COST_FUNCTIONS_FIELDS.COST_TYPE].toLowerCase() === costtype.attribute) {
                            return "";
                        }
                        return textContent;
                    }
                    return "";
                }
            break;

            case PS_MAPPING.FIELDS.PERIOD:
                columnFormatter = function(value, data, type, params, colCompnent, rowComponent) {
                    let rowData = data;
                    if (!rowData[_children] && rowData[COST_FUNCTIONS_FIELDS.COST_CENTER]) {
                        let textContent = [];
                        for (var e in rowData[COST_FUNCTIONS_FIELDS.CONFIG]) {
                            textContent.push(rowData[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.IS_DEFAULT] ? 
                                rowData[COST_FUNCTIONS_FIELDS.CONFIG].length === 1 ? lang.all :
                                obj.displayPeriods(rowData[COST_FUNCTIONS_FIELDS.CONFIG], true): 
                                obj.displayPeriods(rowData[COST_FUNCTIONS_FIELDS.CONFIG][e], false));
                        }
                        return textContent.join(" | ");
                    }  
                    if (!rowData[_children] || rowData[_children].length === 0) {
                        if (rowData[COST_FUNCTIONS_FIELDS.COST_TYPE].toLowerCase() === costtype.calculated || rowData[COST_FUNCTIONS_FIELDS.COST_TYPE].toLowerCase() === costtype.attribute) {
                            return "";
                        }
                    }
                    return "";
                }
            break;

            case COST_FUNCTIONS_FIELDS.RULE: 
                columnFormatter = function(value, data, type, params, colCompnent, rowComponent) {
                    var rowData = data;
                    if (!rowData[_children] || rowData[_children].length === 0) {
                        let textContent = [];
                        for (var e in rowData[COST_FUNCTIONS_FIELDS.CONFIG]) {
                            if (!rowData[COST_FUNCTIONS_FIELDS.COST_CENTER]) { // not mapped costkey  
                                textContent.push(lang.no_description);
                            }  else {                        
                                var readableText = rowData[COST_FUNCTIONS_FIELDS.CONFIG][e].rule === "" ? rowData[COST_FUNCTIONS_FIELDS.CONFIG][e].rule : rowData[COST_FUNCTIONS_FIELDS.CONFIG][e].rule ?  getReadableRuleTextPSLDefinition(rowData[COST_FUNCTIONS_FIELDS.CONFIG][e].rule, obj.props.vectorOptions, obj.props.hiddenVectors) : "";
                                if (rowData[COST_FUNCTIONS_FIELDS.CONFIG][e].rule === "") { // has no defined rule
                                    textContent.push(lang.no_description);
                                } else{ // has defined rule
                                    textContent.push(readableText.replace("_cc",""));
                                }
                            }
                        }
                        if (rowData[COST_FUNCTIONS_FIELDS.COST_TYPE].toLowerCase() === costtype.calculated || rowData[COST_FUNCTIONS_FIELDS.COST_TYPE].toLowerCase() === costtype.attribute) {
                            return "";
                        }
                        return textContent.join(" | ");
                    }
                }
                break;

            case COST_FUNCTIONS_FIELDS.FILTER:
                columnFormatter = function(value, data, type, params, colCompnent, rowComponent) {
                    var rowData = data;
                    if (!rowData[_children] || rowData[_children].length === 0) { 
                        let textContent = [];
                        for (var e in rowData[COST_FUNCTIONS_FIELDS.CONFIG]) {
                            // if row is a parent or an invoilinetype user shouldnt be able to define its filter 
                            if (!rowData[COST_FUNCTIONS_FIELDS.COST_CENTER] || rowData[COST_FUNCTIONS_FIELDS.COST_TYPE] === costtype.invoicelinetype) {
                                textContent.push("");
                            } else {
                                // if user can define a filter for this line
                                var queryFilter = getReadableFilterTextPSLDefinition(tryParse(rowData[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.JSON_FILTER], "") ? tryParse(rowData[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.JSON_FILTER], "").filter : "", obj.props.vectorOptions, obj.props.hiddenVectors);
                                if(queryFilter !== "") { // defined filter
                                    textContent.push(queryFilter.replace("_cc",""));
                                } else { // undefined filter
                                    textContent.push("");
                                }
                            }
                        }
                        return textContent.join(" | ");
                    }
                }
                break;
            
            case PS_MAPPING.FIELDS.METRIC_DESCRIPTION:
                columnFormatter = function(value, data, type, params, colCompnent, rowComponent) {
                    var rowData = data;
                    let textContent = "";
                    for (var e in rowData[COST_FUNCTIONS_FIELDS.CONFIG]) {
                        if (rowData[COST_FUNCTIONS_FIELDS.COST_CENTER]) {                        
                            var readableText = rowData[COST_FUNCTIONS_FIELDS.CONFIG][e].rule === "" ? rowData[COST_FUNCTIONS_FIELDS.CONFIG][e].rule : rowData[COST_FUNCTIONS_FIELDS.CONFIG][e].rule ?  getReadableRuleText(rowData[COST_FUNCTIONS_FIELDS.CONFIG][e].rule, obj.props.vectorOptions, obj.props.hiddenVectors) : "";
                            if (rowData[COST_FUNCTIONS_FIELDS.CONFIG][e].rule !== "") { // has defined rule
                                if (readableText.includes("Metric")) {
                                    if (value && value !== "" && value !== ":") {
                                        var metrics = value.split(",");
                                        for (var e in  metrics) {
                                            textContent += metrics[e] + ", ";
                                        }
                                    }else{
                                        textContent += lang.no_description;
                                    }
                                 }else{
                                    textContent = NA;
                                }
                            }
                        }
                    }
                    if (rowData[COST_FUNCTIONS_FIELDS.COST_TYPE] === costtype.calculated.toLowerCase() || rowData[COST_FUNCTIONS_FIELDS.COST_TYPE].toLowerCase() === costtype.attribute) {
                        return "";
                    }
                    return textContent;
                }
                break;

            case PS_MAPPING.FIELDS.DESCRIPTION_FIELD:
                columnFormatter = function(value, data, type, params, colCompnent, rowComponent) {
                    if (!data[_children] || data[_children].length === 0 ) {
                        return !value ? lang.no_description: value;
                    }
                }
            break;
			default:
			columnFormatter = function(value, data, type, params, colCompnent, rowComponent) {
                return value;
			}
			break;
		}
        
        if (obj.state.glCosts.filter(e=>e.name === colField).length > 0) {
            columnFormatter = function(value, data, type, params, colCompnent, rowComponent) {
                if (!data[PS_MAPPING.CHILDREN] || data[PS_MAPPING.CHILDREN].length === 0) {
                    if (data[COST_FUNCTIONS_FIELDS.COST_TYPE].toLowerCase() === costtype.calculated || data[COST_FUNCTIONS_FIELDS.COST_TYPE].toLowerCase() === costtype.attribute) {
                        return "";
                    } else {
                        return data.GL[colField];
                    }
                }
			}
        }
		return columnFormatter;
    }

    getColumnFormatter(colField) {
        var columnFormatter = "";
        var obj = this;
        switch(colField) {
            case PS_MAPPING.FIELDS.NAME:
                columnFormatter = function(cell, formatterParams) {
                    var rowData = cell.getRow().getData();
                    var p = document.createElement("p");
                    p.textContent = (!cell.getRow().getData()[_children] || cell.getRow().getData()[_children].length === 0 )&& (!cell.getRow().getData()[COST_FUNCTIONS_FIELDS.SHOW_CHILDREN] && cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] !== costtype.calculated && cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] !== costtype.attribute) && rowData['level'] !== 1 ? cell.getValue() + _hidden : cell.getValue();
                    p.title = (!cell.getRow().getData()[_children] || cell.getRow().getData()[_children].length === 0) && (!cell.getRow().getData()[COST_FUNCTIONS_FIELDS.SHOW_CHILDREN] && cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] !== costtype.calculated && cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] !== costtype.attribute) && rowData['level'] !== 1? cell.getValue() + _hidden : cell.getValue();
                    if(cell.getRow().getData()["level"] !== 1) { // moving each child level to the right to appear as a child of its parent
                        var pixels = (cell.getRow().getData()["level"]-1)*20;
                        $(p).css("padding-left",convertPxToViewport(pixels));
                    }
                    if (rowData['level'] === 1) { 
                        $(cell.getRow().getElement()).css({"background-color": "#f3f3f3"});
                        $(cell.getRow().getElement()).css({"border-color":"#DCDCDC"});
                    } else if (rowData["level"] === 2){
                        $(cell.getRow().getElement()).css({"background-color": "rgba(202, 202, 202, 0.5)"});
                        $(cell.getRow().getElement()).css({"border-color":"#c6c6c6"});
                    } else {
                        $(cell.getRow().getElement()).css({"background-color": "rgb(202, 202, 202, 0.8)"});
                        $(cell.getRow().getElement()).css({"border-color":"#cacaca"});
                    }
                    return p;
                }
                break;

            case PS_MAPPING.FIELDS.COST_TERM:
                columnFormatter = function(cell) {
                    var rowData = cell.getRow().getData();
                    if (!rowData[_children] || rowData[_children].length === 0 ) {
                        var p = document.createElement("p");
                        var label = "";
                        var options = obj.state.clientCostTerms;
                        if (rowData[PS_MAPPING.FIELDS.COST_TERM] && rowData[PS_MAPPING.FIELDS.COST_TERM] !== "") {
                            label = options.filter(e=>e.cost_term_id.toString() === rowData[PS_MAPPING.FIELDS.COST_TERM].toString())[0].value;
                        } else{
                            label = options[0].label;
                        }
                        var p = document.createElement("div");
                        if (cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] === costtype.calculated || cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] === costtype.attribute) {
                            cell.getElement().style.minWidth = '55%';
                            p.classList.add("uk-flex-inline", "formula");
                            cell.getElement().style.maxWidth = '100%';
                            var div = document.createElement('div');
                            div.textContent = cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] === costtype.calculated ? "" : _formula;
                            div.classList.add("uk-text-bold");
                            var div2 = document.createElement('div');
                            div2.classList.add("uk-margin-xsmall-left");
                            var attrFormula = cell.getRow().getData()[PS_MAPPING.FIELDS.FORMULA] ? cell.getRow().getData()[PS_MAPPING.FIELDS.FORMULA].replace(/AC/g,'').replace(')','').replace(/f\./g,'').replace("(", "  Of ").toLowerCase() :"";
                            attrFormula = attrFormula.replace("key","").replace("_cc","");
                            var attrFormulaArr = attrFormula.split(" ");
                            var attrFormuaDisplay = [];
                            for(var e in attrFormulaArr) {
                                attrFormuaDisplay.push(capitalizeFirstLetter(attrFormulaArr[e]));
                            }
                            let attFormula = cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] === costtype.calculated ? "" : attrFormuaDisplay?.join(" ").replace('Distinct','distinct').replace('Of','of');
                            attFormula = attFormula? getReadableCostTerm(attFormula, obj.props.vectorOptions, obj.props.hiddenVectors):"";
                            div2.textContent = attFormula;
                            div2.classList.add();
                            p.appendChild(div);
                            p.appendChild(div2);
                        }else{
                            p.textContent = capitalizeFirstLetter(label);
                        }
                        return p;
                    }
                }
            break;

            case COST_FUNCTIONS_FIELDS.COST_TYPE:
                columnFormatter = function(cell) {
                    if (!cell.getRow().getData()[_children] || cell.getRow().getData()[_children].length === 0) {
                        var p = document.createElement("p");
                        p.textContent = capitalizeFirstLetter(cell.getValue().replace(costtype.invoicelinetype,costtype.standard));
                        p.title = capitalizeFirstLetter(cell.getValue());
                        return p;
                    }
                }
            break;
                
            case COST_FUNCTIONS_FIELDS.COST_CENTER:
                columnFormatter = function(cell) {
                    var p = document.createElement("p");
                    p.textContent = cell.getValue() !== _all ? _yes : _no;
                    p.title = cell.getValue() !== _all ? _yes : _no;
                    if (!cell.getRow().getData()[_children] || cell.getRow().getData()[_children].length === 0) {
                        if (cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] === costtype.calculated || cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] === costtype.attribute) {
                            cell.getElement().style.display = 'none';// css({"display": "none !important"})
                        }
                       
                        return p;
                    }
                }
            break;

            case PS_MAPPING.FIELDS.PERIOD:
                columnFormatter = function(cell) {
                    var mainPar = document.createElement("div");
                    if (!cell.getRow().getData()[_children] && cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_CENTER]) {
                        mainPar.classList.add("uk-flex", "uk-flex-column", "uk-width-1-1", "uk-height-1-1");
                        for (var e in cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG]) {
                            var div = document.createElement("div");
                            var cellContainer = document.createElement("div");
                            var span = document.createElement("span");
                            cellContainer.id = "period-drop-down";
                            span.textContent = cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.IS_DEFAULT] ? 
                            cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG].length === 1 ? lang.all :
                            obj.displayPeriods(cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG], true): 
                            obj.displayPeriods(cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e], false);
                            div.appendChild(cellContainer);
                            div.title = span.textContent;
                            div.classList.add("ccf-configure-cell", "uk-flex-inline", "uk-flex-middle");
                            if (cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG].length > 1) {
                                div.classList.add("inner-cell")
                            }
                            div.appendChild(span);
                            mainPar.appendChild(div);
                        }
                    }  
                    if (!cell.getRow().getData[_children] || cell.getRow().getData()[_children].legngth === 0) {
                        if (cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] === costtype.calculated || cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] === costtype.attribute) {
                            cell.getElement().style.display = 'none';// css({"display": "none !important"})
                        }
                    }
                    return mainPar; 

                }
            break;

            case COST_FUNCTIONS_FIELDS.RULE: 
                columnFormatter = function(cell) {
                    var rowData = cell.getRow().getData();
                    if (!rowData[_children] || rowData[_children].length === 0) {
                        var mainPar = document.createElement("div");
                        mainPar.classList.add("uk-flex", "uk-flex-column", "uk-width-1-1", "uk-height-1-1");
                        for (var e in cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG]) {
                            if (!rowData[COST_FUNCTIONS_FIELDS.COST_CENTER]) { // not mapped costkey  
                                var cellContainer = document.createElement("div");
                                cellContainer.innerHTML = lang.no_description;
                                mainPar.appendChild(cellContainer);
                            }  else {                        
                                var readableText = cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e].rule === "" ? cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e].rule : cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e].rule ?  getReadableRuleTextPSLDefinition(cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e].rule, obj.props.vectorOptions, obj.state.metricFields, obj.props.hiddenVectors) : "";
                                var a = document.createElement("div");
                                var cellContainer = document.createElement("div");
                                if (cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e].rule === "") { // has no defined rule
                                    var link = document.createTextNode(lang.no_description);
                                    a.appendChild(link); 
                                    cellContainer.appendChild(a);
                                } else{ // has defined rule
                                    var link = document.createTextNode(readableText.replace("_cc",""));
                                    cellContainer.title = readableText.replace("_cc","");
                                    a.appendChild(link); 
                                    cellContainer.appendChild(a);
                                }
                                a.id = e;
                                var div = document.createElement("div");
                                div.classList.add("ccf-configure-cell", "uk-flex-inline", "uk-flex-middle");
                                div.appendChild(cellContainer);
                                if (cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG].length > 1) {
                                    div.classList.add("inner-cell")
                                }
                                mainPar.appendChild(div);
                            }
                        }
                        if (cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] === costtype.calculated || cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] === costtype.attribute) {
                            cell.getElement().style.display = 'none';
                        }
                    return mainPar;
                }
            }
            break;

            case COST_FUNCTIONS_FIELDS.FILTER:
                columnFormatter = function(cell) {
                    var rowData = cell.getRow().getData();
                    if (!rowData[_children] || rowData[_children].length === 0) { 
                        var mainPar = document.createElement("div");
                        mainPar.classList.add("uk-flex", "uk-flex-column", "uk-width-1-1", "uk-height-1-1")
                        for (var e in cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG]) {
                            let input = document.createElement("input");
                            input.classList.add("uk-cursor-pointer");
                            var cellContainer = document.createElement("div");
                            // if row is a parent or an invoilinetype user shouldnt be able to define its filter 
                            if (!rowData[COST_FUNCTIONS_FIELDS.COST_CENTER] || rowData[COST_FUNCTIONS_FIELDS.COST_TYPE] === costtype.invoicelinetype) {
                                var cellContainer = document.createElement("div");
                                cellContainer.classList.add("ccf-configure-cell", "uk-flex-inline", "uk-flex-middle");
                                var a = document.createElement("div");
                                var link = document.createTextNode("");
                                a.appendChild(link);
                                cellContainer.appendChild(a);
                                 mainPar.appendChild(cellContainer);
                            } else {
                                // if user can define a filter for this line
                                var queryFilter = getReadableFilterTextPSLDefinition(tryParse(cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.JSON_FILTER], "") ? tryParse(cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e][COST_FUNCTIONS_FIELDS.JSON_FILTER], "").filter : "", obj.props.vectorOptions, obj.props.hiddenVectors);
                                var a = document.createElement("div");
                                a.id = e;
                                if(queryFilter !== "") { // defined filter
                                    var a = document.createElement("div");
                                    var link = document.createTextNode(queryFilter.replace("_cc",""));
                                    a.id = e;
                                    cellContainer.title = queryFilter.replace("_cc","");
                                    a.appendChild(link); 
                                    cellContainer.appendChild(a);
                                } else { // undefined filter
                                    var link = document.createTextNode("");
                                    a.appendChild(link); 
                                    a.id = e;
                                    cellContainer.appendChild(a);

                                }
                                var div = document.createElement("div");
                                div.classList.add("ccf-configure-cell", "uk-flex-inline", "uk-flex-middle");
                                div.appendChild(cellContainer);
                                if (cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG].length > 1) {
                                    div.classList.add("inner-cell")
                                }
                                mainPar.appendChild(div);
                            }
                        }
                        if (cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG]) {
                            var height = cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG].length *40
                            $(cell.getRow().getElement()).css({"height": convertPxToViewport(height)})
                        }
                        if (cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] === costtype.calculated || cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] === costtype.attribute) {
                            cell.getElement().style.display = 'none';// css({"display": "none !important"})
                        }
                       return mainPar;
                    }
                }
                break;
            
            case PS_MAPPING.FIELDS.METRIC_DESCRIPTION:
                columnFormatter = function(cell) {
                    var rowData = cell.getRow().getData();
                    var mainPar = document.createElement("div");
                    mainPar.classList.add("uk-flex", "uk-flex-column", "uk-width-1-1")
                    for (var e in cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG]) {
                        if (rowData[COST_FUNCTIONS_FIELDS.COST_CENTER]) {                        
                            var readableText = cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e].rule === "" ? cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e].rule : cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e].rule ?  getReadableRuleText(cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e].rule, obj.props.vectorOptions, obj.props.hiddenVectors) : "";
                            var a = document.createElement("div");
                            var cellContainer = document.createElement("div");
                            if (cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG][e].rule !== "") { // has defined rule
                                if (readableText.includes("Metric")) {
                                    var link = document.createElement('div');
                                    if (cell.getValue() && cell.getValue() !== "" && cell.getValue() !== ":") {
                                        var metrics = cell.getValue().split(",");
                                        for (var e in  metrics) {
                                            var span = document.createElement('span');
                                            span.textContent = metrics[e];
                                            span.classList.add('uk-display-block');
                                            span.title = metrics[e];
                                            link.appendChild(span);
                                        }
                                    }else{
                                        var span = document.createElement('span');
                                            span.textContent = lang.no_description;
                                            span.title = lang.no_description;
                                            link.appendChild(span);
                                    }
                                 }else{
                                    var link = document.createTextNode(NA);
                                    cellContainer.title = NA;
                                }
                                a.appendChild(link); 
                                cellContainer.appendChild(a);
                            }
                            var div = document.createElement("div");
                            div.classList.add("ccf-configure-cell", "uk-flex-inline", "uk-flex-middle");
                            div.appendChild(cellContainer);
                            if (cell.getRow().getData()[COST_FUNCTIONS_FIELDS.CONFIG].length > 1) {
                                div.classList.add("inner-cell")
                            }
                            mainPar.appendChild(div);
                        }
                    }
                    if (cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] === costtype.calculated || cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] === costtype.attribute) {
                        cell.getElement().style.display = 'none';// css({"display": "none !important"})
                    }
                    return mainPar;
                }
                break;

            case PS_MAPPING.FIELDS.DESCRIPTION_FIELD:
                columnFormatter = function(cell) {
                    if (!cell.getRow().getData()[_children] || cell.getRow().getData()[_children].length === 0 ) {
                        var p = document.createElement('p');
                        p.innerText = !cell.getValue() ? lang.no_description: cell.getValue();
                        p.title = !cell.getValue() ? lang.no_description: cell.getValue();
                        return p;
                    }
                }
            break;
			default:
			columnFormatter = function(cell, formatterParams) {
                return cell.getValue();
			}
			break;
		}
        
        if (obj.state.glCosts.filter(e=>e.name === colField).length > 0) {
            columnFormatter = function(cell, formatterParams) {
                var p = document.createElement('p');
                if (!cell.getRow().getData()[PS_MAPPING.CHILDREN] || cell.getRow().getData()[PS_MAPPING.CHILDREN].length === 0) {
                    p.textContent = cell.getRow().getData().GL[colField];
                    p.title = cell.getRow().getData().GL[colField];
                    if (cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] === costtype.calculated || cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] === costtype.attribute) {
                        cell.getElement().style.display = 'none';// css({"display": "none !important"})
                    }
                    return p;
                }
			}
        }
		return columnFormatter;
    }
    
  setColumns(data, columns) {
    var obj = this;
  }

    onTabulatorRenderComplete() {
        if (!this.tabulator) {
            return;
        }
    }

    trackingExportExcelClick = () => {
        let _this = this;
        let query = {
            action: "trackingExportExcelClick",
        }
        let onThenCallback = (data) => {
        } 
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "trackingExportExcelClick",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        };
        fetchAPI(fetchOptions);
    }

    exportTableToExcel(exportOpts) {
      var data = this.linearize(this.tabulator.getData(),[],"children");
      let fileName = capitalizeFirstLetter(this.props.reportTitle)
      var tempSheetName = fileName;
      var mainSheet = tempSheetName.length > 31 ? tempSheetName.substring(0,28) + "..." : tempSheetName;
      var sheets = {};
      sheets[mainSheet] = true;

      var options = exportOpts;
      options.data = data;
      options.fileName = fileName;
      options.sheets = sheets;
      options.isProfitStack = false;

        // var wscols = [
        //     {wch:50},
        //     {wch:15},
        //     {wch:15},
        //     {wch:15},
        //     {wch:15},
        //     {wch:20},
        //     {wch:20},
        //     {wch:20},
        //     {wch:20},
        //     {wch:70},
        //     {wch:100},
        //     {wch:20},
        //     {wch:20},
        // ];
        // options.colsWidth = wscols;
        exportToExcel(options, this.tabulator);
        this.trackingExportExcelClick()
    }

    componentDidUpdate(prevProps) {
        if (this.props.periods !== prevProps.periods) {
            this.setState({
                periods:this.props.periods
            })
        }
    }
    
    getPSLDefinitonsData(scenarioId) {
        var obj = this;
        var query = {
            action: "getPSLDefinitonsData",
            scenario_id: scenarioId,
            accessGroupSection:PSL_DEFINITIONS_RETURN_NAME
        }
          let onThenCallback = function (data) {
            if (data) {
                let response = JSON.parse(data);
                if (response.data) {
                    var branchStateByPeriod = response.branchStates.filter(elt=>elt.period === obj.props.periods[0].value);
                    obj.setState({
                        expanded:false,
                        branchStateData : response.branchStates,
                        branchStateByPeriod:branchStateByPeriod,
                        cost_center: response.cost_center,
                        glCosts: response.glCosts,
                        metricFields:response.metricFields
                    }, function () {
                        obj.setColumns(response.columns, response.data);
                    })
                } else {
                    obj.tabulator.replaceData([]);
                }
            }
          };
          let options = {
            [FETCHAPI_PARAMS.funcName]: "getPSLDefinitonsData",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: lang.observability.output.psl_definition.screen_name,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.psl_definition.request_description.data,
          };
          fetchAPIAjax(options, false);
    }
    setColumns(columns, data) {
        var obj = this;
        if (columns && columns.length > 0) {
            columns.forEach(col => {
                // if (col.field === capitalizeFirstLetter(obj.state.cost_center) + " State") {
                //     col.titleFormatter = obj.addNewRowTitleFormatter;
                //     col.titleFormatterParams = {comp:obj,field: col.field, title: col.title};
                // }
                col.formatter = obj.getColumnFormatter(col.field);
                col.accessor = obj.getColumnAccessor(col.field); 
                col.headerSort = false;
                col.cssClass = "no-cursor";
                col.minWidth = 120;
                if (obj.state.glCosts.filter(e=>e.name === col.field).length === 0) {
                    col.cssClass = "leftAlign";
                }else{
                    col.cssClass = "gl-costs no-cursor";
                }
                if (col.field === COST_FUNCTIONS_FIELDS.COST_TYPE  || col.field === PS_MAPPING.FIELDS.COST_TERM) {
                    col.minWidth = 80;
                }
                if (col.field === COST_FUNCTIONS_FIELDS.COST_CENTER) {
                    col.minWidth = 80;
                    col.cssClass = "assigned-by-branch uk-flex-center";
                }
                if (col.field === PS_MAPPING.FIELDS.PERIOD || col.field === PS_MAPPING.FIELDS.METRIC_DESCRIPTION || col.field === COST_FUNCTIONS_FIELDS.RULE || col.field === COST_FUNCTIONS_FIELDS.FILTER) {
                    col.cssClass = "uk-padding-remove";
                }
                if (col.field === PS_MAPPING.FIELDS.NAME || col.field === PS_MAPPING.FIELDS.DESCRIPTION_FIELD ) {
                    col.maxWidth = 500;
                }
            });
        }
        columns.unshift(EXPAND_ELEMENT);
        obj.tabulator.setColumns(columns);
        obj.tabulator.setData(data);
    }

    getClientCostTerms() {
        var comp = this;
        var query = {
            action: "getClientCostTerms"
        }
        let onThenCallback = function (data) {
            var emptyOption = [{value:"Not Defined", label:"Not Defined", cost_term_id:"",psLabel:""}]
                var options = data.data;
                options.map(function(item){
                    item.value = item.cost_term_value;
                    item.label = item.cost_term_value;
                    item.cost_term_id = item.cost_term_id.toString();
                    item.psLabel =  item.cost_term_value.charAt(0).toUpperCase();
                });
                comp.setState({
                    clientCostTerms:emptyOption.concat(options)
                })
        }
    
        let options = {
          [FETCHAPI_PARAMS.funcName]: "getClientCostTerms",
          [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
          [FETCHAPI_PARAMS.showLoader]: true,
          [FETCHAPI_PARAMS.path]: API_URL.OUTPUT,
          [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
          [FETCHAPI_PARAMS.query]: query,
          [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
          [FETCHAPI_PARAMS.screenName]: lang.observability.output.psl_definition.screen_name,
          [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.psl_definition.request_description.cost_terms,
        };
        fetchAPI(options);
    }
    
  componentDidMount() {
    const _this = this;
    _this.getClientCostTerms();
    var options = {
      layout: "fitColumns",      //fit columns to width of table
      responsiveLayout: false,  //hide columns that dont fit on the table
      addRowPos: "top",          //when adding a new row, add it to the top of the table
      history: true,             //allow undo and redo actions on the table
      pagination: false, //"local",       //paginate the data
      movableColumns: false,     //allow column order to be changed
      autoResize: false,
      resizableColumns: false,
      resizableRows: false,       //allow row order to be changed
      selectable: false,
      dataTree: true,
      dataTreeChildField: _children,
      dataTreeStartExpanded: false,
      dataTreeElementColumn: PS_MAPPING.FIELDS.EXPAND,
      dataTreeCollapseElement: getExpandCollapseButtons(false),
      dataTreeExpandElement: getExpandCollapseButtons(true),
      dataTreeChildIndent: 15,
      dataTreeBranchElement: false, //hide branch element
      invalidOptionWarnings: false,
      reactiveData: true,      //tabulator listens to any change in the data array and updates the table
      virtualDomBuffer: 9000,
      columnHeaderVertAlign: "bottom",
      placeholder: "No data available",
      height: "100%",
      width: "100%",
      renderComplete: _this.onTabulatorRenderComplete,
      dataTreeRowExpanded: function (row, level) {
        row.getData().expanded = true;
      },
      dataTreeRowCollapsed: function (row, level) {
        row.getData().expanded = false;
      },
      accessorDownload: function (dataObj) {
        let params = _this.props.downloadFormatterParams;
        var data = _this.linearize(dataObj.data, [], "children");
        dataObj.data = data;
        return tabulatorExport(dataObj, _this.tabulator, params);
      },
      downloadReady: function (fileContents, blob) {
        toggleLoader(false, "tablesToExcel");
        return blob; //must return a blob to proceed with the download, return false to abort download
      }
    }
    _this.tabulator = new Tabulator("#" + this.props.tableId, options);
    
  }

    appendExportUnderScore(name, level) {
        var underscores= "";
        for (var i=0; i<level; i++){
            underscores += "___";
        }
        return underscores+name;
    }

    linearize(arr, resultArr, childrenField) {
        for(var row in arr) {
            arr[row][VECTOR_ANALYSIS.FIELDS.NAME] = this.appendExportUnderScore(arr[row][VECTOR_ANALYSIS.FIELDS.NAME], arr[row].level);
            resultArr.push(arr[row]);
            if(arr[row][childrenField] && arr[row][childrenField].length > 0 && arr[row].expanded) {
                resultArr = this.linearize(arr[row][childrenField], resultArr, childrenField);
            }
        }
        return resultArr;
    }

    checkAllTree(isExpand, rows) {
        var obj = this;
        var allRows = rows || this.tabulator.getRows();
        var res = false;
        allRows.forEach(function(row) {
            if (isExpand) {
                if (row.getData().children && row._row.modules.dataTree.open) {
                    res = true;
                }
            } else {
                if (row.getData().children && !row._row.modules.dataTree.open) {
                    res =  true;
                }else{
                    if(row.getData().children){
                        var oldRes = res;
                        res = obj.checkAllTree(isExpand, row.getTreeChildren()) === true ? true : oldRes;
                    } 
                }   
            }  
        });
        return res;
    }

  collapseAll(rows = this.tabulator.getRows(), mainCall = true) {
    const _this = this;
    rows.forEach(function (row) {
      row.treeCollapse();
      row.getData().expanded = false;
      if (row.getData().children) {
        _this.collapseAll(row.getTreeChildren());
      }
    });

  }

  expandAll(rows = this.tabulator.getRows(), mainCall = true) {
    const _this = this;
    rows.forEach(function (row) {
      row.treeExpand();
      row.getData().expanded = true;
      if (row.getData().children) {
        _this.expandAll(row.getTreeChildren());
      }
    });

  }

    setPage(pageNumber) {
        this.setState({
            page: pageNumber
        });
    }

    handlePeriodChange(e){
        if(e!==null){
            var branchStateByPeriod = this.state.branchStateData.filter(elt=>elt.period === e.value);
            this.setState({
                period:e.value,
                page: 0,
                branchStateByPeriod: branchStateByPeriod
            });
        }
    }

    // closeModal() {
    //     this.setPage(0);
    // }

  render() {
    return (
      <div className={"psl-definitions-table-container"}>
        <div id={this.props.tableId} className={this.props.tableId} ref={this.props.tableId} />
      </div>
    );
  }
}

export default ProfitStackLineDefinitionsTable;