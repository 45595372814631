import {
	costtype,
	DATASET,
	DEFAULT_DATETIME_FORMAT,
	DEFAULT_TIME_ZONE,
	FormatTypes,
	FY_VALUES,
	GLACCOUNTS_FIELDS,
	PS_MAPPING,
	PSL_RETURN_NAMES,
	QUADRANTS,
	SEGMENTS, MENU_ITEM, SECTION, ALL_WIDGETS, PROFILE_COLUMN
} from './constants';
import {lang} from '../language/messages_en.js';
import {formatValHTML} from './format';
import {extractNumber} from './string.js';

import {is_aN} from './number';
import {getEmbeddedChildName} from "./array";

const _leadingID = PS_MAPPING.FIELDS.LEADING_PSS_ID;
const _isMatched = PS_MAPPING.EXCEPTION_FIELDS.isMatched;
const _returnName = PS_MAPPING.FIELDS.RETURN_NAME;
const _name = PS_MAPPING.FIELDS.NAME;
const _matchedSuffix = lang.pss_map_exception.suffixes.matched;
const _unMatchedSuffix = lang.pss_map_exception.suffixes.variance;
const _children = PS_MAPPING.FIELDS.CHILDREN;
const _costType = PS_MAPPING.FIELDS.COSTTYPE;
const _costKey = PS_MAPPING.FIELDS.COST_KEY;
const _mappingException = PS_MAPPING.FIELDS.MAPPING_EXCEPTION;
const ANCILLARY = GLACCOUNTS_FIELDS.MAP_EXCEPTION_VALUES.ANCILLARY;
const _file = PS_MAPPING.EXCEPTION_FIELDS.FILE;
const _driverType = "driver_type";
const _false = "false";
const _metric = "metric";
const cost_center = "Cost Center";
const _id = PS_MAPPING.FIELDS.PSS_ID;

const $ = require('jquery');


function getTranslationFile() {
    return lang;
}

function formatToCamelNoSpace(value) {
	if (value.trim().indexOf(" ") !== -1) {
		value = value.toLowerCase();
	}
	var noSpaceVal = capitaliseFirstLetterAfterChar(value, " ").replace(/ /g,"");

	return noSpaceVal;
}

function capitaliseFirstLetterAfterChar(string, OnSplitChar) {
	if (OnSplitChar === "" || OnSplitChar === null || !OnSplitChar) {
		OnSplitChar = "_";
	}

	var splitted = string ? string.split(OnSplitChar) : [];
	var finalString = "";
	for (var i = 0; i< splitted.length; i++){
		if(i < splitted.length-1)
			finalString +=  splitted[i].charAt(0).toUpperCase() + splitted[i].slice(1)+OnSplitChar;
		else{
			finalString +=  splitted[i].charAt(0).toUpperCase() + splitted[i].slice(1);
		}
	}
    return finalString;
}

function getNumberOfConos(datasets) {
	var allConos = [];

	for (var dataset in datasets) {
		if (allConos.indexOf(datasets[dataset][DATASET.CONO]) === -1) {
			allConos.push(datasets[dataset][DATASET.CONO]);
		}
	}
	return allConos.length;
}

function setVersion(data, dataSet) {
	var version = ''
	Object.keys(data).forEach(function eachkey(key) {
		if (data[key]["value"] === dataSet) {
			version = data[key][DATASET.VERSION]
		}
	});
	return version
}

function capitalizeFirstLetter(string) { 
	return string?.charAt(0).toUpperCase() + string?.slice(1);
}

function getPSSubElementByName(data, name) {
 	
	var result = "";
	if(data !== null && data !== undefined) {
		Object.keys(data).forEach(function eachKey(key) { 
			if(data[key]['name'] === name) {
				result = data[key]['value'] === undefined ? data[key]['displayValueOnly'] : data[key]['value']; 
			}
		});	
	}

	return result;   	
}

function returnQuadrantAbv(quadrant, returnColor) {

	//.IOP.PT.MN.CR {
	//#aad69c, #abe1fa, #ffea9f, #fcc08b
	
	if(!quadrant) {
		return "";
	}
	var pattern = /[ ]*[1-4]/g;
	quadrant = quadrant.toUpperCase(); 
	var matches = quadrant.match(pattern);
	var rtVal = "";

	if(matches){
		quadrant = quadrant.replace("_"+matches[0],'').replace(matches[0],''); // for quadrant tier ex: IOP_1
	};

	switch(quadrant.toUpperCase()) {
		case QUADRANTS.IOP.name.toUpperCase():
		case QUADRANTS.IOP.name.toUpperCase().replace(/ /g, ""):
		case QUADRANTS.IOP.value.toUpperCase():
			rtVal = returnColor ? "#aad69c" : "IOP";
			break;

		case QUADRANTS.PT.name.toUpperCase():
		case QUADRANTS.PT.name.toUpperCase().replace(/ /g, ""):
		case QUADRANTS.PT.value.toUpperCase():
			rtVal = returnColor ? "#abe1fa" : "PT";
			break;
		
		case QUADRANTS.MN.name.toUpperCase():
		case QUADRANTS.MN.value.toUpperCase():
			rtVal = returnColor ? "#ffea9f" : "MN";
			break;

		case QUADRANTS.CR.name.toUpperCase():
		case QUADRANTS.CR.name.toUpperCase().replace(/ /g, ""):
		case QUADRANTS.CR.value.toUpperCase():
			rtVal = returnColor ? "#fcc08b" : "CR";
			break;

		default:
			rtVal = quadrant;
			break;
	}

	if(matches && !returnColor){
		rtVal += "_"+matches[0].trim();
	};
	return rtVal;

}

function isQuadrant(name, isQuadrant) {

    var quadrant = name.toUpperCase().replace(/[0-9]/g, '').trim();
    var _index = quadrant.indexOf('_');

    if (_index !== -1) {
        quadrant = quadrant.substring(0, _index);
    }

	if(isQuadrant) {
		if(quadrant.startsWith(SEGMENTS.PEAK.abv.toUpperCase()) || quadrant.startsWith(SEGMENTS.DRAIN.abv.toUpperCase()) || quadrant.startsWith(SEGMENTS.FLAT.abv.toUpperCase())){
			return true;
		}
		switch(quadrant) {
			case QUADRANTS.IOP.name.toUpperCase():
			case QUADRANTS.PT.name.toUpperCase():
			case QUADRANTS.MN.name.toUpperCase():
			case QUADRANTS.CR.name.toUpperCase():
			case QUADRANTS.IOP.value.toUpperCase():
			case QUADRANTS.PT.value.toUpperCase():
			case QUADRANTS.MN.value.toUpperCase():
			case QUADRANTS.CR.value.toUpperCase():
				return true;
	
			default:
				return false;
		}	
	} else {
		return false;
	}

}

function setCostHierachy(data) {

	var setCostHierachy = [];

	var Len = data.length;

	for (var i = 0; i < Len; i++) {
		var newItem = {
			name : data[i]['name'],
			pslKey : data[i]['pslKey'],
			returnName : data[i]['returnName']
		}
		
		setCostHierachy.push(newItem);
	}

	return setCostHierachy;

}

function getCostKeyByNameInFact (costHierachy, item) {

	var Len = costHierachy.length;

	for (var i = 0; i < Len; i++) {
		if( costHierachy[i]['returnName'] === item) {
			return costHierachy[i]['pslKey'];
		} 
	}

	return '';
}

function getCostKey (costHierachy, item) {
	
	var Len = costHierachy.length;

	for (var i = 0; i < Len; i++) {
		if( costHierachy[i]['name'] === item) {
			return costHierachy[i]['pslKey'];
		} 
	}

	return '';

}

function excludeTier(tiers, toExclude, quadrant) {

	if(quadrant === "List/Quadrant" || quadrant === "List/Quadrant Tier") return tiers;

	var tierFinal = []
	if(tiers)
		var Len = tiers.length;

	for (var i = 0; i < Len; i++) {
		if(tiers[i]['value'] !== toExclude)
			tierFinal.push(tiers[i]);
	}	
	return tierFinal;
}

function findOptionByKey(options, key) {
	var tempArr = "";
	if (typeof key === "object") {
		tempArr = options ? options.filter(e => !e.isDisabled && (e["value"] === key.value ||  e["label"] === key.label)).length > 0 ? options.filter(e => !e.isDisabled && (e["value"] === key.value ||  e["label"] === key.label))[0] : "" : "";
		return tempArr;
	} else {
		tempArr = options ? options.filter(e => !e.isDisabled && (e["value"] === key ||  e["label"] === key )).length > 0 ? options.filter(e =>!e.isDisabled &&( e["value"] === key ||  e["label"] === key))[0] : "" : "";
	}
	return tempArr;

}
function findOptionByKeyValue(options, key, value) {
	if(key === undefined || value === undefined) {
		return undefined;
	}

	var isNum = is_aN(value);
	var option = options ? options.filter(e => isNum ? Number(e[key]) === Number(value) : e[key] === value)[0] : "";
	return option;
}

function excludeOptions(data, optionsToExclude) {
	var tempArr = data.filter(el=>optionsToExclude.indexOf(el["value"]) === -1);
	return tempArr;
}

/**
 * Performs equality by iterating through keys on an object and returning false
 * when any key has values which are not strictly equal between the arguments.
 * Returns true when the values of all keys are strictly equal.
 */
function shallowEqual(objA, objB) {

	if (objA === objB) {
		return true;
	}

	if (typeof objA !== 'object' || objA === null ||
	 	typeof objB !== 'object' || objB === null) {
		return false;
	}

	var keysA = Object.keys(objA);
	var keysB = Object.keys(objB);

	if (keysA.length !== keysB.length) {
		return false;
	}

	// Test for A's keys different from B.
	var bHasOwnProperty = hasOwnProperty.bind(objB);
	
	for (var i = 0; i < keysA.length; i++) {
		if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
			return false;
		}
	}

	return true;
}

function shallowCompare(instance, nextProps, nextState) {

  return (
    !shallowEqual(instance.props, nextProps) ||
    !shallowEqual(instance.state, nextState)
  );

}

function getIndexOf(dataIn, machineName) {

	var allData = dataIn;
	var index = 0;

	for (var data in allData) {
		if(allData[data].machineName === machineName) {
			index = Number(data);
			break;
		}
	}

	return index;
}

function waitBeforeReload(seconds, newPage) {

	var timeout = setTimeout(function(){
		if(newPage) {
			window.location.href = newPage;
		} else {
			window.location.reload();
		}
	}, seconds);

	//add event listener on clicking OK
	if (document.getElementsByClassName("mm-popup__btn mm-popup__btn--ok") && document.getElementsByClassName("mm-popup__btn mm-popup__btn--ok").length >0) {
		document.getElementsByClassName("mm-popup__btn mm-popup__btn--ok")[0].addEventListener("click", function(){
			clearTimeoutAndLoadPage(timeout, newPage);
		});
		
	//add event listener on clicking anywhere in the body
	document.body.addEventListener("click", function(){
		clearTimeoutAndLoadPage(timeout, newPage);
	});

	}
	

	//add event listener on pressing enter
	window.addEventListener("keyup", function(e){
		if(e.which === 13) {
			clearTimeoutAndLoadPage(timeout, newPage);
		}
	});
}

function clearTimeoutAndLoadPage(timeout, newPage) {

	clearTimeout(timeout);
		if(newPage) {
			window.location.href = newPage;
		} else {
			window.location.reload();
		}
}

function getSectionId(section, id) {

	var sectionId = 0;

	if (id !== '' && id !== undefined) {
		sectionId = id;
	} else {
		switch(section) {

			case "PM":
			default:
				sectionId = 13;
				break;

			case "cmtk":
				sectionId = 12;
				break;

			case "users":
				sectionId = 2;
				break;

			case "data":
				sectionId = 36;
				break;

			case "accessgroups":
				sectionId = 15;
				break;

			case "unitsperLine":
				sectionId = 26;
				break;

			case "avgunitssperline":
				sectionId = 27;
				break;

			case "bridgereport":
				sectionId = 24;
				break;
		}
	}

	return sectionId;
}

function getIndexOfPeriod(data, period) {

	for (var item in data) {
		if (data[item].period === period) {
			return item;
		}
	}
}

function getMaxIndex(array, key) {

	var temp = [];
	for(var element in array) {
		element = array[element];

		temp.push(element.replace(key,""));
	}

	return Math.max(...temp);
}

function ReformatDate(date, twoDigits=false){
	var CustomDateStart = new Date(date);
	let month = CustomDateStart.getMonth() + 1;
    let day = CustomDateStart.getDate();

	month = twoDigits ? (month < 10 ? "0" + month : month) : month;
	day = twoDigits ? (day < 10 ? "0" + day : day) : day;
	var dateStart = CustomDateStart.getFullYear()  + "-" + month + "-" + day;

    return dateStart;
}

const formatDateMMDDYYY = (date) => {
  let customDateStart = new Date(date);
  const year = customDateStart.getUTCFullYear();
  const month = customDateStart.getUTCMonth() + 1;
  const day = customDateStart.getDate(); // if we use getUTCDate it will return: date -3 hours(timezone difference), which will result in returning the day before.
  let dateStart =
    (month < 10 ? "0" + month : month) +
    "/" +
    (day < 10 ? "0" + day : day) +
    "/" +
	  year;
  return dateStart;
};

function checkRequiredItems() {

	var result = true;
	$('.expectedValues').find('[contenteditable="true"]').each( function() { 
	// //clear all inputs
		if($(this).text() === "") {
			result = false;
			return false;		//this statement only breaks out of the loop
		}
	});
	
	return result;
}

function validateRequiredItems() {

	var result = true;
	$('.expectedValues').find('[contenteditable="true"]').each( function() { 
		// //clear all inputs
		var text = $(this).text().replace("$","").replace(/,/g,"");
		if(isNaN(parseFloat(text))) {
			result = false;
			return false;		//this statement only breaks out of the loop
		}
	});
	
	return result;
}

function checkRequiredInput() {

	var result = true;
	if($("#Profit_Description").val() === "") {
		result = false;
		return false;
	}

	return result;
}

function buildNewObject(keys, values) {
	var newObj = {};

	for(var key in keys) {
		newObj[keys[key]] = (values[key] === undefined) ? "" : values[key];
	}

	return newObj;
}

function getIndexOfObject(array, object) {
	var index = -1;
	for(var i = 0; i < array.length; i++) {
		if(JSON.stringify(array[i]) === JSON.stringify(object)) {
			index = i;
			break;
		}
	}

	return index;
}

function isValidTimePeriod() {
	// var pattern = /^\d{4}[Q][1-4]{1}(FY)?$/;
	var pattern = /^\d{4}[Q][1-4]{1}$/;
	if($("#New_Timeperiod") !== undefined) {
		if($("#New_Timeperiod").val().match(pattern)){
			return true;
		}else{
			return false;
		}
	}
}

function setStartsWithPrototype() {
	if(!String.prototype.startsWith) {
		String.prototype.startsWith = function(letter) {
			return this.indexOf(letter === 0);
		}
	}
}

function isValidDate(dateVar) {
	if (Object.prototype.toString.call(dateVar) === "[object Date]") {
		if (isNaN(dateVar.getTime())) {  // d.valueOf() could also work
			return false;
		} else {
			return true;
		}
	} else {
		return false;
	}
}

function isParsable(stringifiedObj) {
	try {
		JSON.parse(stringifiedObj);
		return true;
	} catch (e) {
		return false;
	}
}

function copyObjectValues(object, type) {
	if(object) {
		return JSON.parse(JSON.stringify(object));
	} else if(type === "object") {
		return {};
	} else {
		return [];
	}
}

/**
 * checking if there are keys (properties) in an object
 * @param {*} obj 
 * @returns 
 */
function isObjectNotEmpty (obj) {
	return Object.keys(obj).length > 0;
}

/**
 * checking if all the array elements has keys (properties)
 * @param {*} arr // arr is an array of object
 * @returns 
 */
function areObjectsNotEmpty(arr) {
	return !arr.some(e => Object.keys(e).length === 0);
}

function profileHasColumnsAccess (manageColumnsProfile, manageColsAccess) {
	return (manageColumnsProfile && manageColsAccess && !Object.keys(manageColsAccess).every((k) => !manageColsAccess[k])) || Object.keys(manageColsAccess).every((k) => !manageColsAccess[k]);
}

/**
 * To remove the objects from arr1 if they exist in arr2 based on all the attributes
 * @param {*} arr1 
 * @param {*} arr2 
 * @returns 
 */
function removeDuplicateObject(arr1, arr2){
	return arr1.filter(obj1 => !arr2.some(obj2 => {
	  return Object.keys(obj1).every(k => JSON.stringify(obj1[k]) === JSON.stringify(obj2[k]));
	}));
  }
  
function formatFileSizeByByte(bytes, decimals) {
	if(bytes === 0) return '0 Bytes';
	var k = 1024,
		dm = decimals <= 0 ? 0 : decimals || 2,
		sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
		i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function fancyTimeFormat(time) {   
    // Hours, minutes and seconds
    var hrs = ~~(time / 3600);
    var mins = ~~((time % 3600) / 60);
    var secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + " minutes ";
    ret += (secs < 10 ? "0" : "") + secs + " seconds.";
    return ret;
}

function getSectionExists (menuItems, requiredMenuItemName, isTableau) {
	let found = false;
	menuItems?.forEach(menuItem => {
		if(((menuItem[MENU_ITEM.COLUMNS.MENU_ITEM_MACHINE_NAME] || menuItem[SECTION.RETURN_NAME]) === requiredMenuItemName) || (isTableau && (menuItem[MENU_ITEM.COLUMNS.MENU_ITEM_MACHINE_NAME]?.toLowerCase().replaceAll(" ","_") || menuItem[SECTION.RETURN_NAME]?.toLowerCase().replaceAll(" ","_")) === requiredMenuItemName.toLowerCase()) || requiredMenuItemName === ALL_WIDGETS.FIELDS.ENTITY_STACKS_VALUE) {
			found = true;
		} else if(menuItem.children && menuItem.children.length > 0){
			if(getSectionExists(menuItem.children, requiredMenuItemName, isTableau)){
				found = true;
			}
		}
	});
	return found;
}
  
function convertEasternTZToLocal(receivedDate) {
	var localTime = new Date(receivedDate);	//get the date in local timezone
	var easternTime = new Date(new Date(receivedDate).toLocaleString("en", {timeZone: DEFAULT_TIME_ZONE}));	//get the date in eastern TZ

	let hoursDifference = Math.abs(localTime - easternTime) / 36e5;
	localTime.setHours(localTime.getHours() + hoursDifference);	//add this difference to the current time (also updates date if new day started)

	return localTime;
}

/**
 * converting from local to Easter is easier because the other way around, we can't get the local timezone
 * target, so we have to calculate the difference.
 * @param {*} receivedDate 
 */
function convertLocalToEasternTZ(receivedDate) {
	var millis = new Date(receivedDate).getMilliseconds();
	var ETDate = new Date(new Date(receivedDate).toLocaleString("en", {timeZone: DEFAULT_TIME_ZONE}));	//get the date in eastern TZ
	ETDate.setMilliseconds(millis);		//toLocaleString discards milliseconds, set them to the saved value

	return ETDate;
}
  
function getDayName(dayNumber, abvLength) {
	let daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
	return daysOfWeek[dayNumber].substr(0, abvLength);
}

function getMonthName(monthNumber, abvLength) {
	let monthsOfYear = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
	return monthsOfYear[monthNumber].substr(0, abvLength);
}

function buildDateFromString(dateStr) {
	let datePart = "";
	let timePart = "";
	let meridian = "";

	if(dateStr.match(/T/)) {
		let dateStrArray = dateStr.split("T");	//split between date and time
		datePart = dateStrArray[0];
		let tempTime = dateStrArray[1];
		tempTime = tempTime.split(" ");
		timePart = tempTime[0];
		meridian = tempTime[1];
	} else {
		let dateStrArray = dateStr.split(" ");	//split between date and time
		datePart = dateStrArray[0];
		timePart = dateStrArray[1];
		meridian = dateStrArray[2];
	}

	//building date part, month date year
	datePart = datePart.split(/[^\d]/);	//split on anything that is not a number
	let month = Number(datePart[0]) - 1;
	let date = datePart[1];
	let year = datePart[2];

	//building time part, hours minutes seconds milliseconds
	timePart = timePart.split(/[^\d]/);
	let hours = timePart[0];
	let minutes = timePart[1];
	let seconds = timePart[2];
	let milliseconds = timePart[3];

	if(meridian.toUpperCase === "PM") {
		hours += 12;	//if no meridian or meridian = AM, leave hours as they are
	}

	var builtDate = new Date();
	builtDate.setMonth(month);
	builtDate.setDate(date);
	builtDate.setFullYear(year);
	builtDate.setHours(hours);
	builtDate.setMinutes(minutes);
	builtDate.setSeconds(seconds);
	builtDate.setMilliseconds(milliseconds);

	return builtDate;
}

function formatDate(dateToFormat, format, useNames, abvLength) {
	function adjustSingleDigitLength(digit) {
		return (digit+"").length < 2 ? "0"+digit : digit;
	}

	if(typeof dateToFormat === "string") {
		dateToFormat = buildDateFromString(dateToFormat);
	}

	format = format || DEFAULT_DATETIME_FORMAT;		//use default format if not passed
	let containsMeridian = format.indexOf("a") > -1 ? true : false;

	let year = dateToFormat.getYear() + 1900;	//date.getYear() in js returns current year -1900
	let month = dateToFormat.getMonth() + 1;
	let date = dateToFormat.getDate();
	let tempHours = dateToFormat.getHours();
	let hours = containsMeridian ? tempHours > 12 ? tempHours - 12 : tempHours : tempHours;
	let minutes = dateToFormat.getMinutes();
	let seconds = dateToFormat.getSeconds();
	let milliseconds = dateToFormat.getMilliseconds();
	let day = getDayName(dateToFormat.getDay(), abvLength);
	let meridian = containsMeridian ? tempHours > 11 ? "PM" : "AM" : "";

	let formattedDate = format.replace(/dd/g, adjustSingleDigitLength(date))
							.replace(/a/g, meridian)
							.replace(/MM/g, useNames ? getMonthName(month -1, abvLength) : adjustSingleDigitLength(month))
							.replace(/yy+/g, year)	//match 'yy' or 'yyyy'
							.replace(/hh/g, adjustSingleDigitLength(hours))
							.replace(/.sss/g, "." + adjustSingleDigitLength(milliseconds))
							.replace(/mm/g, adjustSingleDigitLength(minutes))
							.replace(/ss/g, adjustSingleDigitLength(seconds))
							.replace(/EEE/g, adjustSingleDigitLength(day))
							.replace(/'T'/g, "T");	//sometimes a 'T' is passed to separate date from time
	return formattedDate;
}

function logoutIfUnauthenticated(xhr, classObject) {
	if(xhr && xhr.status === 403 && classObject && classObject.logout) {
		classObject.logout();
	}
}

function mergeIntoSingleArray(arrayOfArrays) {
	var resultArr = [];
	for(var i=0; i < arrayOfArrays.length; i++) {
		arrayOfArrays[i].forEach(e=>{
			resultArr.push(e)
		});
	}

	return resultArr;
}

/** The following two functions do the same job #Section_TBD */
function removeDuplicates(arr) {
	var resultArr = [];
	for(var i=0; i < arr.length; i++) {
		if(resultArr.indexOf(arr[i]) === -1) {
			resultArr.push(arr[i]);
		}
	}

	return resultArr;
}

function getUniqueArrayValues(arr) {
	var returnArr = [];
	arr.forEach(item=>{
		if(returnArr.indexOf(item) === -1) {
			returnArr.push(item);
		}
	});

	return returnArr;
}

/**
 * This function does the same job as the two above, but takes into consideration
 * the values of type "object" that cannot be detected with arr.indexOf(obj)
 */
function removeDuplicatesObjects(array) {
	if(!Array.isArray(array)) {
		return null;
	}

	var returnArr = [];
	for(let i=0; i < array.length; i++) {
		let hasDuplicate = false;
		for(let j=i; j < array.length; j++) {
			//j starts at i because the previous indices have already been checked in previous cycles
			//and if a duplicate was found at a later index, the item was not added
			if(i === j && j < array.length - 1) {
				//j has to indicate the element right after the one at index i,
				//but we have to check for the array's length, it is also incremented
				//only in the first cycle otherwise it would skip an index on each cycle
				j += 1;
			}
			if(deepCompareObjects(array[i], array[j]) && i !== j) {
				hasDuplicate = true;
				break;
			}
		}

		if(!hasDuplicate) {
			returnArr.push(array[i]);
		}
	}
	return returnArr;
}

function checkIfArrayIsUnique(myArray) 
{
	for (var i = 0; i < myArray.length; i++) 
	{
		for (var j = 0; j < myArray.length; j++) 
		{
			if (i !== j) 
			{
				if (myArray[i] === myArray[j]) 
				{
					return false; // means there are duplicate values 

				}
			}
		}
	}
	return true; // means there are no duplicate values.

}

function adjustNumberFieldToInteger(ev){
	var temp = ev.target.value.replace(/[^0-9]*/gi, "");
	// here if setting the number field to "2" where it has a value of "2." it will not change,
	// so the logic is to change it to empty then adjust the value
	$(ev.target).val("");
	$(ev.target).val(temp);
}

function getObjectOfAttribute(array, attribute, value) {
	var tempObj = {};

	for(var loopedObj in array) {
		loopedObj = array[loopedObj];

		if(loopedObj[attribute] === value) {
			tempObj = loopedObj;
		}
	}

	return tempObj;
}

export function adjustFormattingForExcel (i, elem) {
	var count = 0;
	var repElem = elem;
	while(!$(repElem).is('td')){
		repElem = $(repElem).parent();
		if(count++ > 20)
			break;
	}


	var format_type = $(elem).attr('format_type');
	if(format_type && format_type.includes("amount:")){
		format_type = format_type.replace("amount","ratio")
	}
	var format = window._format[format_type+"_XLS"];
	if(format_type.indexOf(":") >= 0 && format === undefined){
		format_type = format_type.split(":")[0];
		format = window._format[format_type+"_XLS"];
	}
	let v = $(elem).attr('val');
	if(format_type === "percentage"){
		v = v/100;
	}
	var rep = $("<td format_type='"+format_type+"'>"+v+"</td>");

	rep[0].style["mso-number-format"] =  format;
	$(repElem).replaceWith(rep);
	rep[0].style["mso-number-format"] =  format;
}

function parseBoolean(strValue) {
	if(![true, "true", "t", false, "false", "f"].includes(strValue)) {
		return strValue;
	}
	return [true, "true", "t"].indexOf((strValue+"").toLowerCase()) > -1;
}

function removeSuffix(value, suffix) {
	//if value ends with suffix, remove it from label. do not replace bc it may exist in the middle of the word
	return value.endsWith(suffix) ? value.substring(0, value.length - suffix.length) : value;
}

/**
 * This function is used to compare two objects or two arrays together.
 * For the arrays, there is no resorting, as for the objects, it loops
 * over all the keys in both objects and copies them in order to new objects,
 * and then compares the string versions of the resulting objects
 * @param {*} array1 
 * @param {*} array2 
 */
function deepCompareObjects(array1, array2) {
	if(!array1 || !array2) {
		return false;
	}
	if(!Array.isArray(array1)) {
		array1 = [array1];
	}
	if(!Array.isArray(array2)) {
		array2 = [array2];
	}

	if(array1.length !== array2.length) {
		return false;
	}

	for(var i=0; i < array1.length; i++) {
		let tempObj1 = array1[i];
		let tempObj2 = array2[i];

		if(tempObj1 === tempObj2) {				//if both objects are undefined or equal
			continue;
		} else if(!tempObj1 || !tempObj2) {		//if one of the two is undefined
			return false;
	 	} else if(tempObj1.constructor !== tempObj2.constructor) {		//if both objects are not of the same type
			return false;
		} else if (![Object, Array].includes(tempObj1.constructor)) {		//if they are of a primitive type and not equal (cond 1 not satisfied)
			return false;
		}

		var compareObj1 = {};
		var compareObj2 = {};

		//we have to arrange all the keys in both objects in order to be able to use JSON.stringify
		Object.keys(tempObj1).forEach(key=>{
			compareObj1[key] = tempObj1 && tempObj1[key] && typeof tempObj1[key] === "function" ? tempObj1[key].name : tempObj1[key];
			compareObj2[key] = tempObj2 && tempObj2[key] && typeof tempObj2[key] === "function" ? tempObj2[key].name : tempObj2[key];
		});
		Object.keys(tempObj2).forEach(key=>{
			compareObj1[key] = tempObj1 && tempObj1[key] && typeof tempObj1[key] === "function" ? tempObj1[key].name : tempObj1[key];
			compareObj2[key] = tempObj2 && tempObj2[key] && typeof tempObj2[key] === "function" ? tempObj2[key].name : tempObj2[key];
		});

		try{
			if(JSON.stringify(compareObj1) !== JSON.stringify(compareObj2)) {
				return false;
			}
		} catch (error) {
			return false;
		}
	}

	return true;
}

function returnObjectsInArray(array, object) {
	let currentEntityKey = Object.keys(object);
	for (let [e, entry] of array.entries()) {
		let matching = true;
		let objectKeys = Object.keys(entry);
		let obj = {};
		let index;
		for (let entryKey of objectKeys) {
			if (entry[entryKey] !== object[entryKey]) {
				matching = false;
				break;
			} else {
				matching = true;
				obj = entry;
				index = e;
			}
		}
		if (matching) {
			return {obj: obj, index: index};
		}
	}
	return false;
}

/**
 * this function compares two
 * @param {*} obj1 
 * @param {*} obj2 
 */
function partialCompare(obj1, obj2) {
	if(!obj1 || !obj2) {
		return false;
	}

	let smallerObj = [];
	let largerObj = [];
	let keys = [];
	if(Object.keys(obj1).length <= Object.keys(obj2).length) {
		smallerObj = obj1;
		largerObj = obj2;
	} else {
		smallerObj = obj2;
		largerObj = obj1;
	}
	keys = Object.keys(smallerObj);

	for(var i=0; i < keys.length; i++) {
		let key = keys[i];
		if(!largerObj[key]) {
			return false;
		}

		if(typeof smallerObj[key] === "object" && JSON.stringify(smallerObj[key]) !== JSON.stringify(largerObj[key])) {
			return false;
		}
		if(typeof smallerObj[key] !== "object" && smallerObj[key] !== largerObj[key]) {
			return false;
		}
	}

	return true;
}

function extractValueFromObjects(data, key) {
	key = key || "value";
	return data.map(tempObj=>{
		return tempObj[key];
	})
}

function getAllDatasetsOfCoNo(coNo, optionsListDataSet) {
	let datasets = [];
	for (var set in optionsListDataSet) {
		if (coNo === optionsListDataSet[set][DATASET.CONO]) {
			datasets.push(optionsListDataSet[set].value);
		}
	}
	return datasets;
}

function checkIfMonthsValid(dataset, months) {
	let coNo = getDatasetCoNo(dataset, datasetState.datasetOptions);
	let datasetsOfSameCoNo = getAllDatasetsOfCoNo(coNo, datasetState.datasetOptions);
	let limit = months === FY_VALUES.FY ? 4 : extractNumber(months) / 3;  // /3 bcz if 12 limit is 4, if 9 it's 3 etc.
	for (var set in datasetsOfSameCoNo) {
		//check if the dataset is among the last three datasets
		if (dataset === datasetsOfSameCoNo[set] && Number(set) > datasetsOfSameCoNo.length - limit) {
			return false;
		}
	}
	return true;
}


function getDatasetCoNo(dataset, optionsListDataSet) {
	let coNo = "";
	for (var set in optionsListDataSet) {
		if (dataset === optionsListDataSet[set].value) {
			coNo = optionsListDataSet[set][DATASET.CONO];
			break;
		}
	}
	return coNo;
}

/**
 * This function tries to JSON.parse(obj) and returns the value,
 * if the obj is unparsable, it returns the default value instead of error
 * @param {*} obj 
 * @param {*} defaultValue 
 */
function tryParse(obj, defaultValue) {
	if(typeof obj === "object") {
		return obj;
	}
	
	try {
		return JSON.parse(obj); //.replace(/'/g, "\""));
	} catch(err) {
		return defaultValue;
	}
}

/**
 * This function tries to JSON.stringify(obj) and returns the value,
 * if the obj is already a string, return it
 * @param {*} obj 
 * @param {*} defaultValue 
 */
function tryStringify(obj) {
	if(typeof obj === "string") {
		return obj;
	}
	
	try {
		return JSON.stringify(obj); //.replace(/'/g, "\""));
	} catch(err) {
		return obj;
	}
}

/*
 * This implementation is temporary, correct implementation will be done under PI-10250.
 * @param {*} message 
 */
function localLog(message) {
	var baseUrl = process.env.REACT_APP_BASE_URL;

	var isLocal = baseUrl.includes("localhost");
	if(isLocal) {
		console.log(message);
	}
}

function getTreeLeaves(data) {
	var result = []
	for(var i = 0, length = data.length; i < length; i++){
		if(!data[i].children){
			result.push(data[i]);
		} else if (data[i].children !== undefined) {
			result = result.concat(getTreeLeaves(data[i].children));
		}
	}
	
	return result;
}

function getMonthsNumber(months) {
	return months === FY_VALUES.FY ? 12 : extractNumber(months);
}

function getMonthsTimeperiod(quarter, months) {
	if(!quarter) {
		return quarter;
	}
	var list = [quarter];
	var year = Number(quarter.split("Q")[0]);
	var q_No = Number(quarter.split("Q")[1]);
	var qLimit = getMonthsNumber(months) / 3;

	let count = 1; //bc we already pushed the current quarter to the list
	while(count < qLimit) {
		q_No--;
		if(q_No === 0) {
			q_No = 4;
			year--;
		}

		list.push(year + "Q" + q_No);
		count++;
	}

	return list;
}

function returnQuadrantElement(quad, field, format=FormatTypes.AMOUNT, showDups=true) {
	var div = document.createElement('div');
	div.classList.add('uk-flex', 'uk-flex-right');
	if (quad) {
        div.innerHTML = formatValHTML(quad, FormatTypes.QUADRANT);		
	}
	if (showDups) {
		var div1 = document.createElement('div');
		div1.classList.add("uk-margin-default-left", "uk-display-inline-flex", "uk-flex-middle");
		div1.innerHTML = formatValHTML(field,format);
		
		div.appendChild(div1);
	}
	
	return div;
}

function updateSelected(data, selectedData, nameData, numberData) {
	for (var e in data) {
		if (selectedData.filter(elt=>Number(elt) === Number(data[e].id) || (data[e].display_name && elt.toLowerCase() === data[e].display_name.toLowerCase()) || (data[e].machine_name && elt.toLowerCase() === data[e].machine_name.toLowerCase())).length > 0) {
			data[e].checked = true
		}else if (data[e].machine_name && selectedData.filter(elt=>elt.toLowerCase() === data[e].machine_name.toLowerCase()).length>0) {
			if (data[e].display_name === "Number") {
				var vectorNumber = numberData.filter(elt=>elt.split("-")[0] === data[e].machine_name);
				vectorNumber = vectorNumber.length > 0 ? parseBoolean(vectorNumber[0].split("-")[1]) : false
				data[e].checked = vectorNumber;
			}else {
				var vectorName = nameData?.filter(elt=>elt.split("-")[0] === data[e].machine_name);
				vectorName = vectorName?.length > 0 ? parseBoolean(vectorName[0].split("-")[1]) : false
				data[e].checked = vectorName;
			}
		}
		if (data[e].children && data[e].children.length >0) {
			updateSelected(data[e].children, selectedData, nameData, numberData);
			if (data[e].children.filter(elt=>elt.checked).length === data[e].children.length) {
				data[e].checked = true
			}else if (data[e].children.filter(elt=>elt.checked).length >0 || data[e].children.filter(elt=>elt.indeterminate).length >0 ){
				data[e].indeterminate=true
				data[e].checked = false
			}else{
				data[e].checked = false	
			}
		}
	}
	return data;
}

function removeAttributes(data){
	for (var e in data){
		delete data[e].column_name;
		delete data[e].groupName;
	}
	return data;
}

/**
 * function returns the lines in pss data that have the same leading Id
 * @param {*} leadingPssId 
 * @param {*} mappedLines 
 * @returns 
 */
function getSiblings(leadingPssId, mappedLines) {
	var siblings = [];
	mappedLines.map(function(item){
		if (item[_leadingID] === leadingPssId) {
			item[_isMatched] = parseBoolean(item[_isMatched]);
			siblings.push(item);
		}
	});
	return siblings;
}

function isMappable(rowData) {
	if(![PSL_RETURN_NAMES.UNITS,PSL_RETURN_NAMES.LINES,PSL_RETURN_NAMES.INVOICEHEADERS, PSL_RETURN_NAMES.ORDERS, PSL_RETURN_NAMES.VARCOGS,PSL_RETURN_NAMES.VARREVENUE]
		.includes(rowData[_returnName].toLowerCase()) && rowData[PS_MAPPING.FIELDS.AC_TYPE] !== PS_MAPPING.FIELDS.AC_TYPES.CALCULATED) {
			return true;
	}
	return false;
}

function  updateDeletedProfitStackFields(pssFields, costKey, name, id ) {
	for(var e in pssFields) {
	   
		if(pssFields[e][_costKey] === costKey) {
			pssFields.splice(e,1);
			if (pssFields[e]){
				if(pssFields[e][_name].replace(_unMatchedSuffix,"") === name.replace(_matchedSuffix,"")) {
					pssFields.splice(e,1);
				}
			}
		} else {
			if(pssFields[e][_children] && pssFields[e][_children].length > 0){
				updateDeletedProfitStackFields(pssFields[e][_children], costKey, name, id);
			} else {
				delete pssFields[e][_children];
			}
		}
		if (id && pssFields[e] && pssFields[e][PS_MAPPING.FIELDS.ACTUAL_ID] === id) {
			pssFields.splice(e,1);
		}
	}
	return pssFields;
}

function updateChildAttr(data, costkey, attr, value) {
	for(var row in data) {
		row = data[row];
		if(row[_costKey] === costkey) {
			row[attr[0]] = value.toString();
		} else if(row[_children]){
			row[_children] = updateChildAttr(row[_children], costkey, attr, value);
		}
	}
	return data;
}

function updateChildAttrByKey(data, keyValue, attr, value, key) {
	for(var row in data) {
		row = data[row];
		if(row[key] === keyValue) {
			row[attr[0]] = value;
		} else if(row[_children]){
			row[_children] = updateChildAttrByKey(row[_children], keyValue, attr, value, key);
		}
	}
	return data;
}

function getAttributeFromCostKey (data,costkey,attr, dataAttribute) {
	for(var row in data) {
		row = data[row];
		if(Number(row[_costKey].replace("Insert","")) === costkey) {
			dataAttribute = row[attr];
			break;
		} else if(row[_children]){
			dataAttribute = getAttributeFromCostKey(row[_children], costkey, attr, dataAttribute);
		}
	}
	return dataAttribute;
}


/**
 * This function retrieves the costkeys of all the descendents of an element
 * @param {*} parentCostkey 
 * @param {*} data 
 */
function getChildCostKeys(parentCostkey, data) {
	var costkeys = [];
	for(var row in data) {
		row = data[row];
		if(!row[_costKey]){
			continue;
		}
		row[_costKey] = row[_costKey].replace("Insert", "");
		if(Number(row[_costKey]) === Number(parentCostkey)) {
			for (var child in row[_children]) {
				child = row[_children][child];
				costkeys.push(Number(child[_costKey]));

				if(child[_children]) {
					costkeys = costkeys.concat(getChildCostKeys(child[_costKey], row[_children]));
				}
			}
		} else if(row[_children]) {
			costkeys = costkeys.concat(getChildCostKeys(parentCostkey, row[_children]));
		}
	}

	return costkeys;
}

function  updateChildAttr2(tabulator, tabulatorRows, costkey, attr, value) {
	for(var row in tabulatorRows) {
		let currRow = tabulatorRows[row];
		let rowData = currRow.getData();
		if(rowData[_costKey] === costkey) {
			rowData[attr[0]] = value.toString();
			currRow.update(row);
			if(currRow._row.modules.dataTree){
				currRow.reformat();
			}
		} else if(currRow && !!currRow.children && currRow.getTreeChildren().length > 0){
			updateChildAttr2(tabulator, currRow.getTreeChildren(), costkey, attr, value);
		}
	}
}

function findPslRow(data, attr, value) {
	var found = null;
	for (var e in data) {
		if (data[e][attr] && data[e][attr].toLowerCase().replace("insert","") === (value+"").toLowerCase().replace("insert","")) {
			found = data[e];
			break;
		} else if (data[e][_children]) {
			found = findPslRow(data[e][_children], attr, value);
			if(found) {
				break;
			}
		}
	}
	return found;
}

/**
* function sorts mapped Lines so matchedlines are before unmmacthed
* @param {*} data 
* @returns 
*/
function sortMappedLines(data){
   var res = [];
   var res2 =[]
   for (var e in data) {
	   if (data[e][_isMatched]) {
		   res.push(data[e]);
	   } else if (data[e][_isMatched] === undefined) {
		   res.push(data[e]);
	   } else {
		   res2.push(data[e])
	   }
   }
   return res.concat(res2);
}

function  generateUniqueCostKey(costkeys) {
	var generatedId = "";
	do{
		var inPSFields = 0;
		generatedId = getRandomCostkey();
		for(var i = 0 ; i < costkeys.length; i++){
			if(costkeys && costkeys[i].costkey && Number(costkeys[i].costkey.replace("Insert","")) === generatedId) {
				inPSFields = -1;
				break;
			}else if(costkeys && costkeys[i].costKey && Number(costkeys[i].costKey.replace("Insert","")) === generatedId){
				inPSFields = -1;
				break;
			}
		}         
		if(inPSFields === 0)
			inPSFields = 1;
	} while(inPSFields <= 0)
	costkeys.push({costkey:generatedId.toString()});
	return generatedId;
}

 function generateUniqueIdDB(ids) {
        var generatedId = "";
        do{
            var inPSFields = 0;
            generatedId = getRandomCostkey();
            for(var i = 0 ; i < ids.length; i++){
                if(Number(ids[i].id) === generatedId) {
                    inPSFields = -1;
                    break;
                }
            }        
            if(inPSFields === 0)
                inPSFields = 1;

        } while(inPSFields <= 0)
        ids.push({id:generatedId});
        return generatedId;
    }

	function getRandomCostkey() {
        var costkey = Math.floor(Math.random()*10000) + 1;
        return costkey === 201 ? getRandomCostkey() : costkey;
    }

	function addManualChild(costKey, data, name, childCostKey, newRow) {
        for (var e in data) {
             if (data[e][_costKey] === costKey) {
                 var dataChildren = data[e][_children];
                 var index = "";
                for(var elt in dataChildren){
                     if(!dataChildren[elt][_name].includes(_unMatchedSuffix) && dataChildren[elt][_name].includes(name.replace(_matchedSuffix,"").replace(_unMatchedSuffix,''))) {
                         index =  elt;
                         break;
                     }
                 }
                data[e][_children].splice(Number(index)+1, 0, newRow);
            } 
            if (data[e][_children]) {
                addManualChild(costKey, data[e][_children], name, childCostKey, newRow);
            }
        }
    }

	function reOrderLine(pssFields, toCostKey, line, insertBefore=false) {
        if(toCostKey === line[_costKey]) {
            return pssFields;   //if source and destination are the same, do not enter loop
        }
        var spliced = false;
        var added = false;
        var index = 0;

        while((!spliced || !added) && index < pssFields.length) {
            if(pssFields[index][_costKey] === line[_costKey]) {
                pssFields.splice(index, 1);     //when encountering this element, remove it from list
                spliced = true;
                index--; //re-adjust index after splicing
            } else if(pssFields[index][_costKey] === toCostKey) {
                if(insertBefore) {
                    pssFields.splice(Number(index), 0, line);   //when encountering the target costkey, push the element before it if insertBefore = true
                } else {
                    pssFields.splice(Number(index)+1, 0, line);   //when encountering the target costkey, push the element behind it
                }
                added = true;
                index++; //re-adjust index after splicing
            } else if(pssFields[index][_children]){
                reOrderLine(pssFields[index][_children], toCostKey, line);
            }
            index++;
        }
        return pssFields;
    }

	function  renameMatched(data, childCostKey, flag, name) {
        for (var e in data) {
            if (data[e] && data[e][_costKey] === childCostKey) {
                if (flag) {
                    data[e][_name] = !data[e][_name].includes(_matchedSuffix) ? data[e][_name] + _matchedSuffix : data[e][_name];
                } else if(!name){
                    data[e][_name] = data[e][_name].replace(_matchedSuffix,"").replace(_unMatchedSuffix, "");
					data[e][_isMatched] = undefined;
                } else {
                    data[e][_name] = name.replace(_matchedSuffix, "").replace(_unMatchedSuffix, "") + _unMatchedSuffix;
                }
            } else if (data[e] && data[e][_children]) {
                renameMatched(data[e][_children], childCostKey, flag, name);
            }
        }
    }

	function isDisabledToggleButton(file, item, mappedLines, ancColumns, exceptionMetricsRaw) {
        if(!item || (!!item && item[_mappingException] !== ANCILLARY)) {
            return false;
        }
        if(!item[_isMatched]) {
            var pssSiblings = item[_leadingID] ? getSiblings(item[_leadingID], mappedLines).filter(e => e[_isMatched] && e[_file]) : [];      //fetching siblings of leading PS Line
            for(var e in pssSiblings) {
                var sibling = pssSiblings[e];
                if (sibling[_driverType] === _metric) {
                    var metric = exceptionMetricsRaw.filter(e=>e.value === sibling[_file]);
                    if (metric.length > 0 && metric[0].is_cost_center === _false) {
                        return true;
                    }
                } else {
                    var columns = ancColumns.filter(el=>el.file_type === sibling[_file]);
                    var costCenterCol = columns.filter(el=>el.name === cost_center);
                    if(costCenterCol.length === 0) {
                        return true;
                    }
                }
            }
            return false;
        } else {
            var ancFile = file;
            if(!file && !!item) {
                item = getLeadingItem(item[_leadingID], mappedLines);
                ancFile = item[_file];
            }
            if (!!item && item[_driverType] === _metric) {
                var metric = exceptionMetricsRaw.filter(e=>e.value === ancFile);
                if (metric.length > 0 && metric[0].is_cost_center === 'true') {
                    return false;
                } else {
                    return true;
                }
            } else {
                var columns = ancColumns.filter(el=>el.file_type === ancFile);
                var costCenterCol = columns.filter(el=>el.name === cost_center);
                return costCenterCol.length === 0;    
            }    
        }
    }

	function  getLeadingItem(leadingId, mappedLines) {
        mappedLines = mappedLines ?  mappedLines.filter(el=>el[_id] === leadingId) :  this.state.mappedLines.filter(el=>el[_leadingID] === leadingId);
        for (var e in mappedLines) {
            var file = mappedLines[e][_file];
            if (file) {
                return mappedLines[e];
            }
        }
        return "";
    }

	function arrayIn(arr1, arr2) {
		for (var e in arr1) {
			if (arr2.includes(arr1[e])) {
				return true;
			}
		}
		return false;
	}

	/* functions searches in data for the row of a given costkey and returns it
	* @param {*} costKey 
	* @param {*} data 
	* @returns 
	*/
	function retrieveRowByAttr(value, data, attr){
	   for (var e in data) {
		   let element = data[e][attr];
		   if (attr === _costKey && data[e][attr]) {
				element = data[e][attr].replace("Insert","");
		   }
		   if (value.includes(element)) {
			   return data[e];
		   }else if (data[e][PS_MAPPING.FIELDS.CHILDREN]) {
			   let rowData = retrieveRowByAttr(value, data[e][PS_MAPPING.FIELDS.CHILDREN], attr);
			   if (rowData) return rowData;
		   }
	   }
   }

   function constructMenuDropDown(groups, groupsRetNames) {
		var options = [];
		for (var e in groups) {
			let opt = {};
			opt.displayName = groups[e];
			opt.id = groupsRetNames[e];
			options.push(opt);
		}
		return options;
	}

	/**
	 * 
	 * @param {*} list as in array of data
	 * @param {*} keyGetter as in the function with field i-e : e=>e["type"] (group by type) || e=>e["name"](group by name)]
	 * @returns 
	 */
	function groupBy(list, keyGetter){
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
  }

  function convertFirstLetterToUpperCase(str) {
	var splitStr = str.toLowerCase().split(' ');
	for (var i = 0; i < splitStr.length; i++) {
		splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
	}

	return splitStr.join(' ');
}

function isRedDot (data){
	if (!data.name || (((!data[_children] && data.is_column_expandable && data.is_expandable)
	|| (data[_children] && data[_children].length ===0)) && data[_costType] === costtype.standard)
	|| (data[_costType] === costtype.calculated && (!data.formula || data.formula.length ===0))){
		return true;                   
	} 
	if (data && data !== null && data[_children] && data.children.length !== 0 ) {
		let dot =  hasRedDot(data[_children]) === 1 ? true : false;
		return dot;
	}
	return false;
};

function hasRedDot (result){
	for (var row in result) {
		if (!result[row].is_column_expandable || !result[row].is_expandable) {
			continue;
		} 
		else if (!result[row].name || !result[row][_children]) {
			return 1;
		}
		// else if (result[row][_children]) {
		//     return 1;
		// }
		if (result[row][_children]) {
			if (hasRedDot(result[row][_children]) !== 1) {
				continue;
			} else {
				return 1;
			}
		}
	}
};

function generateActionToken(length){
    //edit the token allowed characters
    var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
    var b = [];  
    for (var i=0; i<length; i++) {
        var j = (Math.random() * (a.length-1)).toFixed(0);
        b[i] = a[j];
    }
    return b.join("")+"_"+Date.now();
}

function sliceCharLength(label,length){
	let labelLength = label?.split('').length
	let labelText = label;
	if (labelLength > length) {
		labelText = labelText.substring(0, length) + '...';
	}
	return labelText;
}

  /**
  * loops over the lines and their children and updates it to checked or not checked
  * @param {} arr
  * @param {*} isChecked
  * @returns
  */

function updateAllData(arr, isChecked){
    arr.forEach((row) => {
      row.checked = isChecked;
      if (!row.children) {
        return;
      }
      updateAllData(row.children, isChecked);
    });

    return arr;
  };

    /**
   * loops over the lines and their children to find the matching node and updates it
   * @param {} arr
   * @param {*} node
   * @returns
   */
	function updateData(arr, node){
		arr.forEach((row) => {
		  if (row.value === node.value) {
			row.checked = node.checked || true;
			return;
		  }
		  if (!row.children) {
			return;
		  }
		  updateData(row.children, node);
		});
	  }


// Helper function to get the month number from a period string
function getMonthNumber(period) {
    return Number(period.split("P")[1]);
}

// Helper function to get the year number from a period string
function getYearNumber(period) {
    return Number(period.split("P")[0]);
}

      
/**
 * checks if period range is consecutive or not
 * @param {*} periods 
 * @returns 
 */
const arePeriodsConsecutive = (periodsArray) => {
    if (periodsArray.length <= 1) {
        return true; // If there's only one or zero periods, they are considered consecutive
      }
    
      // Sort the periods array in ascending order
      periodsArray.sort();
    
      for (let i = 1; i < periodsArray.length; i++) {
        const currentPeriod = periodsArray[i];
        const prevPeriod = periodsArray[i - 1];
    
        const currentMonth = getMonthNumber(currentPeriod);
        const prevMonth = getMonthNumber(prevPeriod);
    
        const currentYear = getYearNumber(currentPeriod);
        const prevYear = getYearNumber(prevPeriod);
    
        if (currentMonth === 1) {
          // If current month is January, then check if the year is consecutive
          if (currentYear !== prevYear + 1) {
            return false;
          }
        } else {
          // If current month is not January, then check if the month is consecutive
          if (currentYear !== prevYear || currentMonth !== prevMonth + 1) {
            return false;
          }
        }
      }
    
      return true;
    }

const formatUsdCurrency = (value) => {
	if (!value || value === '$') {
		return '';
	}

	// Remove non-digit and non-decimal characters
	const numericValue = parseFloat(String(value).replace(/[^\d.]/g, ''));

	// Check if the value is a valid number
	if (isNaN(numericValue)) {
		return '';
	}

	// Separate the value into integer and decimal parts
	const [integerPart, decimalPart] = numericValue.toFixed(2).split('.');

	// Format integer part with comma separators
	const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

	// Combine formatted parts and return the USD currency string
	const formattedValue = `${formattedIntegerPart}.${decimalPart}`;
	return `$${formattedValue}`;
};


function hasLettersAndSpecialCharacters(str) {
    if (str) {
        const lettersAndSpecialCharsRegex = /[a-zA-Z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        return lettersAndSpecialCharsRegex.test(str);
    }
    return false;
}



  /**
   * This function takes an array of strings and concatenates them to 1 string with commas and 'and'
   * The result wll look like this: 
   *  ex1: string1, string 2 and string 3
   *  ex2: string1 and string 2
   * @param {*} arrayOfString 
   * @returns 
   */
  function concatenateStrings (arrayOfString) {
    if(!arrayOfString || !arrayOfString?.length) {
      return "";
    }
    let joinedLabels;
    if (arrayOfString.length > 2) {
      const labelsCopy = [...arrayOfString];
      
      const lastTwo = labelsCopy.splice(-2); // Extract the last two elements
      joinedLabels = labelsCopy.join(', ') + ', ' + lastTwo.join(' and ');
    } else {
      joinedLabels = arrayOfString.join(' and ');
    }
    return joinedLabels;
  }

function splitAndGetURLLastHrefElement(href) {
	let hrefSplitArray = href ? href.split('/') : window.location.href.split('/');
	return hrefSplitArray[hrefSplitArray.length - 1];
}

function onEnterClick(event, elementId) {
	if (event.key === "Enter") {
		// Trigger the button element with a click
		if (document.getElementById(elementId) && document.getElementById(elementId) !== null) {
			document.getElementById(elementId).click();
		}
	}
}

/**
 * This function takes an array of indices and an array
 * it will remove items from array having the indices sent in indicesToRemove
 * We are using ToReversed() to prevent removing wrong indices since .splice changed the indices of the items in array
 * @param {*} indicesToRemove
 * @param {*} array
 */
function removeByIndicesFromArray(indicesToRemove, array) {
	for (let i of indicesToRemove.toReversed()) {
		array.splice(i, 1);
	}
}

/**
 * This function takes an array of indices, an array and an arrayToCheck
 * it will push the indices of common items between array and arrayToCheck into indicesToRemove
 * @param {*} indicesToRemove
 * @param {*} array
 * @param {*} arrayToCheck
 */
function pushArrayIndicesToRemove(indicesToRemove, array, arrayToCheck) {
	for (let f of array) {
		if (arrayToCheck.includes(f)) {
			indicesToRemove.push(array.indexOf(f));
		}
	}
}

/**
 * Converts a number to its word representation.
 *
 * This function handles numbers from 0 to 99 and returns their
 * corresponding English word representation.
 *
 * @param {number} number - The number to convert.
 * @returns {string} The word representation of the number.
 *
 * @example
 * numberToWords(0); // "zero"
 * numberToWords(13); // "thirteen"
 * numberToWords(25); // "twenty-five"
 * numberToWords(99); // "ninety-nine"
 *
 * @throws Will return number as it is if the input
 *         number is not within the supported range.
 */

function numberToWords(number) {
	const ones = [
		"zero", "one", "two", "three", "four", "five",
		"six", "seven", "eight", "nine"
	];
	const teens = [
		"ten", "eleven", "twelve", "thirteen", "fourteen",
		"fifteen", "sixteen", "seventeen", "eighteen", "nineteen"
	];
	const tens = [
		"", "", "twenty", "thirty", "forty",
		"fifty", "sixty", "seventy", "eighty", "ninety"
	];

	if (number < 10) {
		return ones[number];
	} else if (number < 20) {
		return teens[number - 10];
	} else if (number < 100) {
		return tens[Math.floor(number / 10)] + (number % 10 !== 0 ? "-" + ones[number % 10] : "");
	} else {
		return number;
	}
}

/** This function returns an updated array with distinct values based distinctField
 **/
function getDistinctOptions(options, distinctField) {
	return options.filter((value, index, self) => index === self.findIndex((t) => t[distinctField] === value[distinctField]));
}

function sortArrayOfObjects(array, sortField, sortType = "asc") {
	if (!Array.isArray(array)) {
		return [];
	}

	// Create a shallow copy to avoid mutating the original array
	const sortedArray = copyObjectValues(array);

	return sortedArray.sort((a, b) => {
		const aValue = a[sortField];
		const bValue = b[sortField];

		let comparison = 0;

		// Numeric comparison
		if (typeof aValue === "number" && typeof bValue === "number") {
			comparison = aValue - bValue;
		}
		// String comparison (case-insensitive)
		else if (typeof aValue === "string" && typeof bValue === "string") {
			comparison = aValue.localeCompare(bValue, undefined, {sensitivity: "base"});
		}
		// Date comparison
		else if (aValue instanceof Date && bValue instanceof Date) {
			comparison = aValue - bValue;
		}
		// Fallback for other types
		else {
			comparison = (aValue > bValue) - (aValue < bValue);
		}

		return sortType === "asc" ? comparison : -comparison;
	});
}

function getFormattedDraggableOptions(options, titleField, orderField) {
	return options.map((item, index) => {
		item.id = item.id || index + 1;
		item.order = item.order || item[orderField];
		item.title = item[titleField];
		return item;
	});
}

/**
 * function loops through profilColumns and extract the columnName of the returnNAme provided
 * @param {*} returnName
 * @param {*} arrData
 * @returns column name of the returnname found in arrData
 */
function getColumnName(returnName, arrData, checkedItems) {
	let data = arrData;
	for (let e in data) {
		let children = data[e][PROFILE_COLUMN.CHILDREN];

		let name = children ? children.filter((e) => e[PROFILE_COLUMN.RETURN_NAME] === returnName) : "";
		if (name.length > 0) {
			return name[0][PROFILE_COLUMN.NAME];
		} else {
			let newName = getEmbeddedChildName(returnName, children, checkedItems);
			if (newName !== returnName) {
				return newName;
			}
		}
	}
	return returnName;
}



export {getTranslationFile, capitaliseFirstLetterAfterChar, shallowCompare, excludeTier, findOptionByKey, findOptionByKeyValue, excludeOptions, 
	getPSSubElementByName, setVersion,returnQuadrantAbv, setCostHierachy, getCostKey, getCostKeyByNameInFact, isQuadrant, getIndexOf, waitBeforeReload, getSectionId,
	getIndexOfPeriod, getMaxIndex, formatDate, checkRequiredItems, checkRequiredInput, validateRequiredItems, buildNewObject, getIndexOfObject, isValidTimePeriod,
	setStartsWithPrototype, isValidDate, copyObjectValues, ReformatDate, isParsable, formatFileSizeByByte, fancyTimeFormat,
	getSectionExists, convertEasternTZToLocal, convertLocalToEasternTZ, 
	logoutIfUnauthenticated, getNumberOfConos, capitalizeFirstLetter, formatToCamelNoSpace, removeDuplicates, mergeIntoSingleArray, 
	checkIfArrayIsUnique, getObjectOfAttribute, getUniqueArrayValues, parseBoolean, removeSuffix, adjustNumberFieldToInteger, deepCompareObjects,
	extractValueFromObjects, getAllDatasetsOfCoNo, getDatasetCoNo, tryParse, localLog, removeDuplicatesObjects, getTreeLeaves, getMonthsNumber, getMonthsTimeperiod,
	partialCompare,returnQuadrantElement, updateSelected, removeAttributes, getSiblings, isMappable, updateDeletedProfitStackFields, updateChildAttr, updateChildAttr2, findPslRow,
	sortMappedLines, generateUniqueCostKey, generateUniqueIdDB, getRandomCostkey, addManualChild, reOrderLine, renameMatched, isDisabledToggleButton,
	getLeadingItem, arrayIn, retrieveRowByAttr,constructMenuDropDown, groupBy, convertFirstLetterToUpperCase, getAttributeFromCostKey, getChildCostKeys,
	 isRedDot, hasRedDot, updateChildAttrByKey,generateActionToken,sliceCharLength, removeDuplicateObject, updateAllData, updateData, tryStringify,
	 formatDateMMDDYYY, arePeriodsConsecutive, checkIfMonthsValid, formatUsdCurrency, isObjectNotEmpty, areObjectsNotEmpty, profileHasColumnsAccess, 
   hasLettersAndSpecialCharacters, concatenateStrings, returnObjectsInArray, splitAndGetURLLastHrefElement, onEnterClick, removeByIndicesFromArray, pushArrayIndicesToRemove, numberToWords,
	getFormattedDraggableOptions, sortArrayOfObjects, getDistinctOptions, getColumnName};

