//This is the second sub component
import React, { Component } from 'react';
import Tabulator from "tabulator-tables";

import { setLocalStorageValueByParameter, toggleLoader } from '../class/common.js';
import { ALL_WIDGETS, API_URL, COST_FUNCTIONS_FIELDS, PS_MAPPING, VECTOR_ANALYSIS, costtype } from '../class/constants.js';
import { convertPxToViewport } from '../class/formatting';
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from '../class/networkUtils.js';
import { exportToExcel, tabulatorExport } from '../class/tabulatorExport.js';
import { capitalizeFirstLetter, tryParse } from '../class/utils.js';
import { getExpandCollapseButtons } from '../newComponents/tabulatorComponents';
import { lang } from '../language/messages_en';
const _formula = "Formula: ";
/**
 * Profit Stack Line Definitions table
 * @author [Sarah Farjallah]
 */
const _children = PS_MAPPING.FIELDS.CHILDREN;
const $ = require("jquery");

const baseUrl = process.env.REACT_APP_BASE_URL;
const _yes = "Yes";
const _no = "No";
const _all = "ALL";
const NA = "N/A";
const PSL_DEFINITIONS_RETURN_NAME = ALL_WIDGETS.FIELDS.MODEL_COHERENCE;
const no_description = "No Description";
const _hidden = " (Hidden)";
const EXPAND_ELEMENT =
{
    title: '',
    field: PS_MAPPING.FIELDS.EXPAND,
    headerSort: false,
    dontFilter: true,
    cssClass: "expand-collapse-cell",
    width: '5%',
    download: false
}

class ModelCoherenceTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterFinal: "[]",
            columns: [],
            level:0,
            lowestLevel:0,
            expanded: false,
            period:"",
            cost_center: ""
        }
        this.fetchAPI = fetchAPI.bind(this);
        this.setColumns = this.setColumns.bind(this);
        this.getTabulator = this.getTabulator.bind(this);
        this.expandAll = this.expandAll.bind(this);
        this.collapseAll = this.collapseAll.bind(this);
        this.exportTableToExcel = this.exportTableToExcel.bind(this);
        this.getColumnFormatter = this.getColumnFormatter.bind(this);
        this.checkAllTree = this.checkAllTree.bind(this);
        this.linearize = this.linearize.bind(this);
        this.appendExportUnderScore = this.appendExportUnderScore.bind(this);
        this.arrangePeriods = this.arrangePeriods.bind(this);
        this.displayPeriods = this.displayPeriods.bind(this);
        this.getModelCoherenceData = this.getModelCoherenceData.bind(this);
        this.setPage = this.setPage.bind(this);
        this.handlePeriodChange = this.handlePeriodChange.bind(this);
        // this.closeModal = this.closeModal.bind(this);
    }

    getTabulator() {
		if(this.tabulator)
			return this.tabulator;
		return null;
    }

    // addNewRowTitleFormatter(cell, params) {
    //     let div = document.createElement("div");
    //     let a = document.createElement('a');
    //     a.innerHTML = params.title; 
    //     a.classList.add('uk-cursor-pointer', 'branchState');
    //     a.onclick = function(){
    //         window._profitIsleOpenModal("branchStateModal");
    //         // obj.setPage(0);
    //     }
    //     div.appendChild(a);
    //     return div;
    // }

    arrangePeriods(periods) {
        periods = periods.reverse();
        var periodMessageArr = [];
        for (var elt in periods) {
            if (Number(elt) === 0) {
                periodMessageArr.push(periods[elt].value);
            }else{
                // previous period is equal to current period +1
                if (periods[elt].value.split("P")[0] === periods[Number(elt)-1].value.split("P")[0] && Number(periods[Number(elt)-1].value.split("P")[1]) + 1 === Number(periods[Number(elt)].value.split("P")[1])) {
                    if (periodMessageArr[periodMessageArr.length-1] !== "➜") { //if no arrow add arrow to show consecutive periods
                        periodMessageArr.push("➜");
                    }
                    if (Number(elt) === periods.length -1) { // if reached end of array of periods we add last period to look like this p1 ➜ p12
                        periodMessageArr.push(periods[Number(elt)].value);
                    }
                    // if previous year is last period of year and current period is first period of the next year then they are still consecutive ex: 2019p12 and 2020P01
                } else if (Number(periods[elt].value.split("P")[0]) - 1  === Number(periods[Number(elt)-1].value.split("P")[0]) && (Number(periods[Number(elt)-1].value.split("P")[1])  ===  12 || Number(periods[Number(elt)-1].value.split("P")[1])  ===  13) &&  Number(periods[Number(elt)].value.split("P")[1]) === 1){
                    if (periodMessageArr[periodMessageArr.length-1] !== "➜") { //add arrow to show pweriods are still consective
                        periodMessageArr.push("➜");
                        if (Number(elt) === periods.length -1) {// if reached end of array of periods we add last period to look like this p1 ➜ p12
                            periodMessageArr.push(periods[Number(elt)].value);
                        }
                    }
                } else if (periodMessageArr[periodMessageArr.length-1] === "➜") {// no more consecutive periods code will reach here and check if last item was ➜ then it adds previous period and appends a , to distinguish between stand alone periods and condesctive ones ex: 2019p8,2019p9,201p10p10,2020p1 it should be 2019P8->P10, 2020P1
                    periodMessageArr.push(periods[Number(elt)-1].value);
                    periodMessageArr.push(", \n");
                    periodMessageArr.push(periods[Number(elt)].value);
                }else { // no more consecutive periods and last item index is not ➜ then it was a stand alone period so we add, and append the current period
                    periodMessageArr.push(", \n")
                    periodMessageArr.push(periods[Number(elt)].value)
                }
            }
        }
        return periodMessageArr.join(" ");
    }

    periodExist(period, config) {
        var found = false;
        for (var e in config) {
            if (!config[e][COST_FUNCTIONS_FIELDS.IS_DEFAULT]) {
                if (config[e].periods.includes(period)) {
                    found = true
                }
            }
        }
        return !found;
    }

    onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }

    displayPeriods(config, is_default) {
        var periods = this.props.periods;
        var toBeDisplayedPeriods = []
        if (!is_default) {
            var uniquePeriods = [];
            for (var i in config.periods) {
                if (!uniquePeriods.includes(config.periods[i])) {
                    uniquePeriods.push(config.periods[i]);
                }
            }
            for (var e in uniquePeriods){
                if (periods.filter(elt=>elt.value === uniquePeriods[e]).length > 0){
                    toBeDisplayedPeriods.push({value:uniquePeriods[e]})
                }
            }
            return this.arrangePeriods(toBeDisplayedPeriods);
        } else {
            for (var e in periods) {
                var found = false;
                for (var elt in config) {
                    if (config[elt].periods.includes(periods[e].value)) {
                        found = true;
                    }
                }
                if (!found) {
                    toBeDisplayedPeriods.push(periods[e]);
                }
            }
            return  this.arrangePeriods(toBeDisplayedPeriods);
        }
    }



    getColumnFormatter(colField) {
        var columnFormatter = "";
        var obj = this;
        switch(colField) {
            case PS_MAPPING.FIELDS.NAME:
                columnFormatter = function(cell, formatterParams) {
                    var rowData = cell.getRow().getData();
                    var p = document.createElement("p");
                    p.textContent = (!cell.getRow().getData()[_children] || cell.getRow().getData()[_children].length === 0 )&& (!cell.getRow().getData()[COST_FUNCTIONS_FIELDS.SHOW_CHILDREN] && cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] !== costtype.calculated && cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] !== costtype.attribute) && rowData['level'] !== 1 ? cell.getValue() + _hidden : cell.getValue();
                    p.title = (!cell.getRow().getData()[_children] || cell.getRow().getData()[_children].length === 0) && (!cell.getRow().getData()[COST_FUNCTIONS_FIELDS.SHOW_CHILDREN] && cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] !== costtype.calculated && cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] !== costtype.attribute) && rowData['level'] !== 1? cell.getValue() + _hidden : cell.getValue();
                    if(cell.getRow().getData()["level"] !== 1) { // moving each child level to the right to appear as a child of its parent
                        var pixels = (cell.getRow().getData()["level"]-1)*20;
                        $(p).css("padding-left",convertPxToViewport(pixels));
                    }
                    if (rowData['level'] === 1) {
                        $(cell.getRow().getElement()).css({"background-color": "#f3f3f3"});
                        $(cell.getRow().getElement()).css({"border-color":"#DCDCDC"});
                    } else if (rowData["level"] === 2){
                        $(cell.getRow().getElement()).css({"background-color": "rgba(202, 202, 202, 0.5)"});
                        $(cell.getRow().getElement()).css({"border-color":"#c6c6c6"});
                    } else {
                        $(cell.getRow().getElement()).css({"background-color": "rgb(202, 202, 202, 0.8)"});
                        $(cell.getRow().getElement()).css({"border-color":"#cacaca"});
                    }
                    return p;
                }
                break;
            case "accuracy":
                columnFormatter = function(cell) {
                    return cell.getValue();
                }
                break;
            case "accuracy_percentage":
                columnFormatter = function(cell) {
                    return cell.getValue();
                }
                break;
		}

		return columnFormatter;
    }


    onTabulatorRenderComplete() {
        if (!this.tabulator) {
            return;
        }
    }

    trackingExportExcelClick = () => {
        let _this = this;
        let query = {
            action: "trackingExportExcelClick",
        }
        let onThenCallback = (data) => {
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "trackingExportExcelClick",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
        };
        fetchAPI(fetchOptions);
    }

    exportTableToExcel(exportOpts) {
      var data = this.linearize(this.tabulator.getData(),[],"children");
      let fileName = capitalizeFirstLetter(this.props.reportTitle)
      var tempSheetName = fileName;
      var mainSheet = tempSheetName.length > 31 ? tempSheetName.substring(0,28) + "..." : tempSheetName;
      var sheets = {};
      sheets[mainSheet] = true;

      var options = exportOpts;
      options.data = data;
      options.fileName = fileName;
      options.sheets = sheets;
      options.isProfitStack = false;

        exportToExcel(options, this.tabulator);
        this.trackingExportExcelClick()
    }

    componentDidUpdate(prevProps) {
        if (this.props.periods !== prevProps.periods) {
            this.setState({
                periods:this.props.periods
            })
        }
    }

    getModelCoherenceData(scenarioId) {
        var obj = this;
        toggleLoader(true, "getModelCoherenceData");
        var query = {
            action: "getModelCoherenceData",
            scenario_id: scenarioId,
            accessGroupSection:PSL_DEFINITIONS_RETURN_NAME
        }
      setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
      $.ajax({
            url: baseUrl + API_URL.DATA_MODELING,
            async: true,
            type: 'POST',
            crossDomain: true,
            xhrFields: {withCredentials: true},
            data: JSON.stringify(query),
            success: function (response, result, xhr) {
                response = JSON.parse(response);
                if (response.data) {
                    obj.setState({
                        expanded:true,
                    }, function () {
                        obj.setColumns(response.columns, response.data);
                    })
                } else {
                    obj.tabulator.replaceData([]);
                }

            },
            error: function (error) {
                // alertAndLogError(error);
            },
            complete: function (xhr, textStatus) {
                if(xhr.status === 403){
                    obj.logout();
                }
                toggleLoader(false, "getModelCoherenceData");
            }
        });
    }

    setColumns(columns, data) {
        var obj = this;
        if (columns && columns.length > 0) {
            columns.forEach(col => {
                col.formatter = obj.getColumnFormatter(col.field);
                col.headerSort = false;
                col.cssClass = "no-cursor";
                col.minWidth = 120;

            });
        }
        columns.unshift(EXPAND_ELEMENT);
        obj.tabulator.setColumns(columns);
        obj.tabulator.setData(data);
    }


  componentDidMount() {
    const _this = this;
    var options = {
      layout: "fitColumns",      //fit columns to width of table
      responsiveLayout: false,  //hide columns that dont fit on the table
      addRowPos: "top",          //when adding a new row, add it to the top of the table
      history: true,             //allow undo and redo actions on the table
      pagination: false, //"local",       //paginate the data
      movableColumns: false,     //allow column order to be changed
      autoResize: false,
      resizableColumns: false,
      resizableRows: false,       //allow row order to be changed
      selectable: false,
      dataTree: true,
      dataTreeChildField: _children,
      dataTreeStartExpanded: false,
      dataTreeElementColumn: PS_MAPPING.FIELDS.EXPAND,
      dataTreeCollapseElement: getExpandCollapseButtons(false),
      dataTreeExpandElement: getExpandCollapseButtons(true),
      dataTreeChildIndent: 15,
      dataTreeBranchElement: false, //hide branch element
      invalidOptionWarnings: false,
      reactiveData: true,      //tabulator listens to any change in the data array and updates the table
      virtualDomBuffer: 9000,
      columnHeaderVertAlign: "bottom",
      placeholder: "No data available",
      height: "100%",
      width: "100%",
      renderComplete: _this.onTabulatorRenderComplete,
      dataTreeRowExpanded: function (row, level) {
        row.getData().expanded = true;
      },
      dataTreeRowCollapsed: function (row, level) {
        row.getData().expanded = false;
      },
      accessorDownload: function (dataObj) {
        let params = _this.props.downloadFormatterParams;
        var data = _this.linearize(dataObj.data, [], "children");
        dataObj.data = data;
        return tabulatorExport(dataObj, _this.tabulator, params);
      },
      downloadReady: function (fileContents, blob) {
        toggleLoader(false, "tablesToExcel");
        return blob; //must return a blob to proceed with the download, return false to abort download
      }
    }
    _this.tabulator = new Tabulator("#" + this.props.tableId, options);

  }

    appendExportUnderScore(name, level) {
        var underscores= "";
        for (var i=0; i<level; i++){
            underscores += "___";
        }
        return underscores+name;
    }

    linearize(arr, resultArr, childrenField) {
        for(var row in arr) {
            arr[row][VECTOR_ANALYSIS.FIELDS.NAME] = this.appendExportUnderScore(arr[row][VECTOR_ANALYSIS.FIELDS.NAME], arr[row].level);
            resultArr.push(arr[row]);
            if(arr[row][childrenField] && arr[row][childrenField].length > 0 && arr[row].expanded) {
                resultArr = this.linearize(arr[row][childrenField], resultArr, childrenField);
            }
        }
        return resultArr;
    }

    checkAllTree(isExpand, rows) {
        var obj = this;
        var allRows = rows || this.tabulator.getRows();
        var res = false;
        allRows.forEach(function(row) {
            if (isExpand) {
                if (row.getData().children && row._row.modules.dataTree.open) {
                    res = true;
                }
            } else {
                if (row.getData().children && !row._row.modules.dataTree.open) {
                    res =  true;
                }else{
                    if(row.getData().children){
                        var oldRes = res;
                        res = obj.checkAllTree(isExpand, row.getTreeChildren()) === true ? true : oldRes;
                    }
                }
            }
        });
        return res;
    }

  collapseAll(rows = this.tabulator.getRows(), mainCall = true) {
    const _this = this;
    rows.forEach(function (row) {
      row.treeCollapse();
      row.getData().expanded = false;
      if (row.getData().children) {
        _this.collapseAll(row.getTreeChildren());
      }
    });

  }

  expandAll(rows = this.tabulator.getRows(), mainCall = true) {
    const _this = this;
    rows.forEach(function (row) {
      row.treeExpand();
      row.getData().expanded = true;
      if (row.getData().children) {
        _this.expandAll(row.getTreeChildren());
      }
    });

  }

    setPage(pageNumber) {
        this.setState({
            page: pageNumber
        });
    }

    handlePeriodChange(e){
        if(e!==null){
            var branchStateByPeriod = this.state.branchStateData.filter(elt=>elt.period === e.value);
            this.setState({
                period:e.value,
                page: 0,
                branchStateByPeriod: branchStateByPeriod
            });
        }
    }



  render() {

    return (
      <div className={"psl-definitions-table-container"}>
        <div id={this.props.tableId} className={this.props.tableId} ref={this.props.tableId} />
        {}
      </div>
    );
  }
}

export default ModelCoherenceTable;