import {advancedFormatCurrency} from "./number";

import {API_URL, FORMAT_SETTINGS_PRIVATE, UI_ACTIONS} from "./constants";
import {alertAndLogError} from "./jqueries";
import React, {Component} from "react";
import {FORMAT_SETTINGS, FormatTypes} from "./constants.js";
import { getTranslationFile } from "./utils";
import { setLocalStorageValueByParameter } from "./common";
import { Segment } from "../components/Segment";
import { findLongestStringByTextWidth } from "./string.js";


const $ = require("jquery");
const lang = getTranslationFile();

class Format {
    constructor(formatSettings, callback){
        var settings = formatSettings !== null ? JSON.parse(JSON.stringify(formatSettings)) : {
            [FORMAT_SETTINGS.CURRENCY_SIGN]:"$",
            [FORMAT_SETTINGS.CURRENCY_SIGN_POSITION]:"left",
            [FORMAT_SETTINGS.PERCENTAGE_SIGN_POSITION]:"right",
            [FORMAT_SETTINGS.NEGATIVE_VALUES_FORMAT]:"minus",
            [FORMAT_SETTINGS.NEGATIVE_COLORING]:"enabled",
            [FORMAT_SETTINGS.NEGATIVE_COLOR]:"#CC0000",
            [FORMAT_SETTINGS.AMOUNT_DECIMALS]:"0",
            [FORMAT_SETTINGS.AMOUNT_MULTIPLIER]:"N/A",
            [FORMAT_SETTINGS.RATIO_DECIMALS]:"2",
            [FORMAT_SETTINGS.PERCENTAGE_DECIMALS]:"2",
            [FORMAT_SETTINGS.NUMERIC_DECIMALS]:"0",
            [FORMAT_SETTINGS.NUMERIC_MULTIPLIER]:"N/A"
        };

        for(var setting in settings){
            this[setting] = settings[setting];
        }
        this.formatNegative = this.formatNegative.bind(this);
        this.formatAmount = this.formatAmount.bind(this);
        this.formatRatio = this.formatRatio.bind(this);
        this.formatPercent = this.formatPercent.bind(this);
        this.formatQuadrant = this.formatQuadrant.bind(this);
        this.formatNumeric = this.formatNumeric.bind(this);
        this.formatBasisPoint = this.formatBasisPoint.bind(this);


        this.formatValHTML = this.formatValHTML.bind(this);
        this.formatValHTMLOld = this.formatValHTMLOld.bind(this);
        this.formatValReact = this.formatValReact.bind(this);
        this.formatValString = this.formatValString.bind(this);
        this.formatValNumber = this.formatValNumber.bind(this);

        this.getUserParamKeys = this.getUserParamKeys.bind(this);
        this.getClientParamKeys = this.getClientParamKeys.bind(this);

        this.fetchUserSettings = this.fetchUserSettings.bind(this);
        this.replaceCurrency = this.replaceCurrency.bind(this);

        this.fillExcelFormat = this.fillExcelFormat.bind(this);
        if(!formatSettings && window.location.pathname !== "/") {
            this.fetchUserSettings(callback);

            //start tracking url_load, when pathname ==="/" there is a counter call in Login.js
            if(!window._pi_isCurrentlyTracking()) {
                //make sure there isn't already a trackingData obj bc we dont want to delete it on url change
                window._pi_initialiseTracking(UI_ACTIONS.URL_LOAD, "", "", "", window.location.pathname === "/");
            }
        } else{
            callback();
        }
    }

    replaceCurrency(val){
        if(!val)
            return val;
        if(val.indexOf("$") >= 0)
            return val.split("$").join(this[FORMAT_SETTINGS.CURRENCY_SIGN]);
        else
            return val;
    }

    getUserParamKeys(){
        this.userParams = [
            FORMAT_SETTINGS.AMOUNT_DECIMALS,
            FORMAT_SETTINGS.AMOUNT_MULTIPLIER,
            FORMAT_SETTINGS.RATIO_DECIMALS,
            FORMAT_SETTINGS.PERCENTAGE_DECIMALS,
            FORMAT_SETTINGS.NUMERIC_DECIMALS,
            FORMAT_SETTINGS.NUMERIC_MULTIPLIER];
        for(var att in this.ratio_sub_types){
            this.userParams.push(FORMAT_SETTINGS.RATIO_DECIMALS+":"+this.ratio_sub_types[att].key);
        }
        return this.userParams;
    }

    getClientParamKeys(){
        this.clientParams = [
            FORMAT_SETTINGS.AMOUNT_DECIMALS,
            FORMAT_SETTINGS.AMOUNT_MULTIPLIER,
            FORMAT_SETTINGS.RATIO_DECIMALS,
            FORMAT_SETTINGS.PERCENTAGE_DECIMALS,
            FORMAT_SETTINGS.NUMERIC_DECIMALS,
            FORMAT_SETTINGS.NUMERIC_MULTIPLIER,
            FORMAT_SETTINGS.CURRENCY_SIGN,
            FORMAT_SETTINGS.CURRENCY_SIGN_POSITION,
            FORMAT_SETTINGS.PERCENTAGE_SIGN_POSITION,
            FORMAT_SETTINGS.NEGATIVE_VALUES_FORMAT,
            FORMAT_SETTINGS.NEGATIVE_COLORING,
            FORMAT_SETTINGS.NEGATIVE_COLOR];
        for(var att in this.ratio_sub_types){
            this.clientParams.push(FORMAT_SETTINGS.RATIO_DECIMALS+":"+this.ratio_sub_types[att].key);
        }
        return this.clientParams;
    }

    setData(formatSettings){
        for(var setting in formatSettings){
            this[setting] = formatSettings[setting];
        }
        let ratio_sub_types = [];
        for(var att in this.sub_types){
            let s = this.sub_types[att];
            ratio_sub_types.push({key:s.returnname, name:this.replaceCurrency(s.name)});
        }
        this.ratio_sub_types = ratio_sub_types;
        this.fillExcelFormat();
    }

    fetchUserSettings(callback){
        var baseUrl = process.env.REACT_APP_BASE_URL;
        var path = API_URL.FORMAT;
        var query = "";
        var comp = this;
        setLocalStorageValueByParameter(window.location.host+"_"+"lastRequestSentTime",new Date());
        fetch(`${baseUrl}${path}`, {body:JSON.stringify({action:"getUserFormatting"}), method:"POST", mode:'cors', credentials:'include'})
            .then((response)=>{
                return response.json()})
            .then((data)=>{
                comp.setData(data);
                this.fillExcelFormat();
                document.dispatchEvent(new CustomEvent("format_change"));
                callback();
            })
            .catch((error)=>{
                alertAndLogError(error);
                callback();
            });
    }

    fillExcelFormat() {
        this.XLS_formats = [];
        var initialFormat = "#,##0";
        var leftCurrencyUnit = this[FORMAT_SETTINGS.CURRENCY_SIGN_POSITION] !== "right" ? this[FORMAT_SETTINGS.CURRENCY_SIGN] : "";
        var rightCurrencyUnit = this[FORMAT_SETTINGS.CURRENCY_SIGN_POSITION] === "right" ? this[FORMAT_SETTINGS.CURRENCY_SIGN] : "";
        var leftPercentUnit = this[FORMAT_SETTINGS.PERCENTAGE_SIGN_POSITION] === "left" ? "%" : "";
        var rightPercentUnit = this[FORMAT_SETTINGS.PERCENTAGE_SIGN_POSITION] !== "left" ? "%" : "";
        var addParenthesis = this[FORMAT_SETTINGS.NEGATIVE_VALUES_FORMAT] !== "minus";
        var color = this[FORMAT_SETTINGS.NEGATIVE_COLORING] === "enabled" ? "RED" : "BLACK";
        if(!this[FORMAT_SETTINGS.AMOUNT_DECIMALS]){
            this[FormatTypes.AMOUNT + "_XLS"] = leftCurrencyUnit+initialFormat+rightCurrencyUnit;
        } else {
            let paddWidth = Number(this[FORMAT_SETTINGS.AMOUNT_DECIMALS]);
            var newFormat = initialFormat+(paddWidth>0?".":"");
            newFormat = this.padRight(paddWidth + newFormat.length, newFormat, '0');
            newFormat = leftCurrencyUnit+newFormat+rightCurrencyUnit;
            this[FormatTypes.AMOUNT + "_XLS"] = newFormat+";["+color+"]"+(addParenthesis?"(":"-")+newFormat+(addParenthesis?")":"");
        }
        this.XLS_formats.push(FormatTypes.AMOUNT+"_XLS");
    
        if(!this[FORMAT_SETTINGS.NUMERIC_DECIMALS]){
            this[FormatTypes.NUMERIC + "_XLS"] = initialFormat;
        } else {
            let paddWidth = Number(this[FORMAT_SETTINGS.NUMERIC_DECIMALS]);
            var newFormat = initialFormat+(paddWidth>0?".":"");
            newFormat = this.padRight(paddWidth + newFormat.length, newFormat, '0');
            this[FormatTypes.NUMERIC + "_XLS"] = newFormat+";["+color+"]"+(addParenthesis?"(":"-")+newFormat+(addParenthesis?")":"");
        }
        this.XLS_formats.push(FormatTypes.NUMERIC+"_XLS");
    
        if(!this[FORMAT_SETTINGS.PERCENTAGE_DECIMALS]){
            this[FormatTypes.PERCENTAGE + "_XLS"] = leftPercentUnit+initialFormat+rightPercentUnit;
        } else {
            let paddWidth = Number(this[FORMAT_SETTINGS.PERCENTAGE_DECIMALS]);
            var newFormat = initialFormat+(paddWidth>0?".":"");
            newFormat = this.padRight(paddWidth + newFormat.length, newFormat, '0');
            newFormat = leftPercentUnit+newFormat+rightPercentUnit;
            this[FormatTypes.PERCENTAGE + "_XLS"] = newFormat+";["+color+"]"+(addParenthesis?"(":"-")+newFormat+(addParenthesis?")":"");
        }
        this.XLS_formats.push(FormatTypes.PERCENTAGE+"_XLS");
    
        if(!this[FORMAT_SETTINGS.RATIO_DECIMALS]){
            this[FormatTypes.RATIO + "_XLS"] = initialFormat;
        } else {
            let paddWidth = Number(this[FORMAT_SETTINGS.RATIO_DECIMALS]);
            var newFormat = initialFormat+(paddWidth>0?".":"");
            newFormat = this.padRight(paddWidth + newFormat.length, newFormat, '0');
            newFormat = leftCurrencyUnit+newFormat+rightCurrencyUnit;
            this[FormatTypes.RATIO + "_XLS"] = newFormat+";["+color+"]"+(addParenthesis?"(":"-")+newFormat+(addParenthesis?")":"");
        }
        this.XLS_formats.push(FormatTypes.RATIO+"_XLS");
    
        for(var s in this.ratio_sub_types){
            let sub_type = this.ratio_sub_types[s];
            if(!this[FORMAT_SETTINGS.RATIO_DECIMALS+":"+sub_type.key]){
                this[FormatTypes.RATIO +":"+sub_type.key+ "_XLS"] = this[FormatTypes.RATIO + "_XLS"];
                this.XLS_formats.push(FormatTypes.RATIO+":"+sub_type.key+"_XLS");
                continue;
            }
            let paddWidth = Number(this[FORMAT_SETTINGS.RATIO_DECIMALS+":"+sub_type.key]);
            var newFormat = initialFormat+(paddWidth>0?".":"");
            newFormat = this.padRight(paddWidth + newFormat.length, newFormat, '0');
            newFormat = leftCurrencyUnit+newFormat+rightCurrencyUnit;
            this[FormatTypes.RATIO +":"+sub_type.key+ "_XLS"] = newFormat+";["+color+"]"+(addParenthesis?"(":"-")+newFormat+(addParenthesis?")":"");
            this.XLS_formats.push(FormatTypes.RATIO+":"+sub_type.key+"_XLS");
        }
    }    

    padLeft(width, string, padding) { 
        return (width <= string.length) ? string : this.padLeft(width, padding + string, padding)
    }

    padRight(width, string, padding) { 
        return (width <= string.length) ? string : this.padRight(width, string + padding, padding)
    }

    formatNegative(v, negative, symbol, position){
        var pref = "";
        var suff = "";
        var addSymbol = function(){
            if(position === "left") {
                pref = symbol + pref;
            } else if(position === "right"){
                suff = suff+symbol
            }
        }
        if(negative){
            if(this[FORMAT_SETTINGS.NEGATIVE_VALUES_FORMAT] === "parentheses"){
                pref += "(";
                suff += ")";
                addSymbol();
            } else {
                addSymbol();
                pref = "-" + pref;
            }
            if(this[FORMAT_SETTINGS.NEGATIVE_COLORING] === "enabled"){
                pref = "<span style='color:"+this[FORMAT_SETTINGS.NEGATIVE_COLOR]+"; text-overflow: ellipsis; overflow: hidden; display: flow-root' >"+pref;
                suff = suff+"</span>";
            }
        } else {
            addSymbol();
        }
        return pref+v+suff;
    }

    formatQuadrant(value){
        if(!value)
            return  "<div class='red'>N/A</div>";
           
        value = value.toLowerCase();
        let segment = new Segment();
        let segmentObj = segment.getSegmentObject(value);

        segment.value = value;
        var html = "<div class='segment' style='background-color: ";
        html += segmentObj?.color;
        html += "; color: " + segmentObj?.textColor +"'"
        html += " > <span class='"+(segmentObj?.isHidden?"visibility-none":"")+"'>"+segmentObj?.label+"</span> </div>";
        return html;
    }


    formatAmount(v, ignore_multiplier) {
        if(!v)
            return "-";
        let decimals = this[FORMAT_SETTINGS.AMOUNT_DECIMALS];
        let multiplier = !ignore_multiplier?this[FORMAT_SETTINGS.AMOUNT_MULTIPLIER]:"N/A";
        var vf = advancedFormatCurrency(Math.abs(v), "", decimals, multiplier, this[FORMAT_SETTINGS_PRIVATE.SETTING_NAMES.NUMERIC_FORMAT_NAME]);
        if(vf === '-' || vf === 'N/A')
            return vf;
        return this.formatNegative(vf, v<0, this[FORMAT_SETTINGS.CURRENCY_SIGN],
            this[FORMAT_SETTINGS.CURRENCY_SIGN_POSITION]);
    }

    formatRange(v, ignore_multiplier, subType) {
        if(!v)
            return "-";
        v = v.split(",");
        return this.formatValHTML(v[0], subType, ignore_multiplier) + " " + lang.COMMON.TO.toLowerCase() + " " + this.formatValHTML(v[1], subType, ignore_multiplier);
    }

    formatRatio(v, ignore_multiplier, subtype) {
        if(!v)
            return "-";
        let decimals, multiplier;
        if(subtype){
            decimals = this[FORMAT_SETTINGS.RATIO_DECIMALS+":"+subtype];
            multiplier = !ignore_multiplier?this[FORMAT_SETTINGS.RATIO_MULTIPLIER+":"+subtype]:"N/A";
        }
        if(!decimals) decimals = this[FORMAT_SETTINGS.RATIO_DECIMALS];
        if(!multiplier) multiplier = !ignore_multiplier?this[FORMAT_SETTINGS.RATIO_MULTIPLIER]:"N/A";

        var vf = advancedFormatCurrency(Math.abs(v), "", decimals, multiplier, this[FORMAT_SETTINGS_PRIVATE.SETTING_NAMES.NUMERIC_FORMAT_NAME]);
        if(vf === '-' || vf === 'N/A')
            return vf;
        return this.formatNegative(vf, v<0);
        // removing $ sign from ratio
        // return this.formatNegative(vf, v<0, this[FORMAT_SETTINGS.CURRENCY_SIGN], this[FORMAT_SETTINGS.CURRENCY_SIGN_POSITION]);  
    }

    formatNumeric(v, ignore_multiplier) {
        if(!v)
            return "-";
        let decimals = this[FORMAT_SETTINGS.NUMERIC_DECIMALS];
        let multiplier = !ignore_multiplier?this[FORMAT_SETTINGS.NUMERIC_MULTIPLIER]:"N/A";
        var vf = advancedFormatCurrency(Math.abs(v), "", decimals, multiplier, this[FORMAT_SETTINGS_PRIVATE.SETTING_NAMES.NUMERIC_FORMAT_NAME]);
        if(vf === '-' || vf === 'N/A')
            return vf;
        return this.formatNegative(vf, v<0);
    }

    formatBasisPoint(v, ignore_multiplier) {
        if(!v)
            return "-";
        let decimals = this[FORMAT_SETTINGS.BASIS_POINT_DECIMALS];
        let multiplier = !ignore_multiplier?this[FORMAT_SETTINGS.BASIS_POINT_MULTIPLIER]:"N/A";
        var vf = advancedFormatCurrency(Math.abs(v), "", decimals, multiplier, this[FORMAT_SETTINGS_PRIVATE.SETTING_NAMES.NUMERIC_FORMAT_NAME]);
        if(vf === '-' || vf === 'N/A')
            return vf;
        return this.formatNegative(vf, v<0);
    }

    formatPercent(v, ignore_multiplier) {
        if (!v)
            return "-"
        let decimals = this[FORMAT_SETTINGS.PERCENTAGE_DECIMALS];
        let multiplier = !ignore_multiplier?this[FORMAT_SETTINGS.PERCENTAGE_MULTIPLIER]:"N/A";
        if(Math.abs(v) > 1000000){
            let isNegativeValue = v < 0;
            return isNegativeValue ? "<-999.9%" : ">999.9%";
        }
        var vf = advancedFormatCurrency(Math.abs(v), "", decimals, multiplier, this[FORMAT_SETTINGS_PRIVATE.SETTING_NAMES.NUMERIC_FORMAT_NAME]);
        if(vf === '-' || vf === 'N/A')
            return vf;
        return this.formatNegative(vf, v<0, "%",
            this[FORMAT_SETTINGS.PERCENTAGE_SIGN_POSITION]);
    }

    formatValReact(val, format_type, ignore_multiplier){
        return <span dangerouslySetInnerHTML={{__html:this.formatValHTML(val, format_type, ignore_multiplier)}} />;
    }

    formatValHTML(val, format_type, ignore_multiplier){
        if(typeof format_type !== "string" && format_type != null){
            if(format_type.format_type === null){
                return this.formatValHTMLOld(val, format_type);
            }
        }
        let format_sub_type = null;
        if(format_type.indexOf(":") >= 0){
            format_sub_type = format_type.split(":")[1];
            format_type = format_type.split(":")[0];
        }
        var ret = "";
        var dont = false;
        switch(format_type){
            case FormatTypes.TEXT:
                ret = val === undefined ? "-" : val + "";
                dont = true;
                break;
            case FormatTypes.QUADRANT:
                ret = this.formatQuadrant(val, ignore_multiplier, format_sub_type);
                dont = true;
                break;
            case FormatTypes.AMOUNT:
                ret = this.formatAmount(val, ignore_multiplier, format_sub_type);
                break;
            case FormatTypes.RATIO:
                ret = this.formatRatio(val, ignore_multiplier, format_sub_type);
                break;
            case FormatTypes.PERCENTAGE:
                ret = this.formatPercent(val, ignore_multiplier, format_sub_type);
                break;
            case FormatTypes.BASIS_POINT:
                ret = this.formatBasisPoint(val, ignore_multiplier, format_sub_type);
                break;
            case FormatTypes.NUMERIC:
                ret = this.formatNumeric(val, ignore_multiplier, format_sub_type);
                break;
            case FormatTypes.RANGE:
                ret = this.formatRange(val, ignore_multiplier, format_sub_type);
                break;
            default:
                ret = val;
                dont = true;
                break;
        }
        if(dont){
            return ret;
        } else {
            let alignment = format_type === FormatTypes.TEXT || format_type === FormatTypes.QUADRANT ? " left " : " right ";
            return "<span style='width:100%; text-align:"+alignment+";  text-overflow: ellipsis; overflow: hidden; display: flow-root' val='" + val + "' format_type='" + format_type +(format_sub_type?(":"+format_sub_type):"")+ "' >" + ret + "</span>";
        }
    }
    
    formatValHTMLOld(val, formatter){
        if(!val)
            return "-";
        if(!formatter)
            return this.formatValHTML(val,FormatTypes.TEXT);
        if(formatter.format === "money") {
            if(formatter.precision > 0){
                return this.formatValHTML(val, FormatTypes.RATIO);
            } else {
                return this.formatValHTML(val, FormatTypes.AMOUNT);
            }
        } else if(formatter.format === "number") {
            return this.formatValHTML(val,FormatTypes.NUMERIC);
        } else if(formatter.format === "ratio") {
            return this.formatValHTML(val*100,FormatTypes.PERCENTAGE);
        } else if(formatter.format === "percentage") {
            return this.formatValHTML(val,FormatTypes.PERCENTAGE);
        } else if (formatter.format === "quadrant") {
            return this.formatValHTML(val,FormatTypes.QUADRANT);
        } else {
            return this.formatValHTML(val,FormatTypes.TEXT);
        }
    }

    formatValString(val, format_type, ignore_multiplier){
       return $("<span style='width:100%; text-align:right; display: inline-grid'>"+window._format.formatValHTML(val, format_type, ignore_multiplier)+"</span>").text();
    }

    formatValNumber(val, format_type, ignore_multiplier){
        let formattedValue = window._format.formatValString(val, format_type, ignore_multiplier).replace(/%/gi, "").replace("$", "");
       return formattedValue === "-" ? "0" : formattedValue;
    }

}

function formatValReact(val, format_type, ignore_multiplier){
    return <span dangerouslySetInnerHTML={{__html:formatValHTML(val, format_type, ignore_multiplier)}} />;
}

function formatValHTML(val, format_type, ignore_multiplier){
    if(Array.isArray(val) && val.length>1){
        return val.map(e=>window._format.formatValHTML(e, format_type, ignore_multiplier)).join(" ");
    }
    return window._format.formatValHTML(val, format_type, ignore_multiplier);
}

function formatValString(val, format_type, ignore_multiplier){
    return window._format.formatValString(val, format_type, ignore_multiplier);
}

function formatLongestValString(val, format_type, ignore_multiplier, font){
    if(Array.isArray(val) && val.length>1){
        val = findLongestStringByTextWidth(val, font);
    }
    return window._format.formatValString(val, format_type, ignore_multiplier);
}

function formatValNumber(val, format_type, ignore_multiplier){
    return window._format.formatValNumber(val, format_type, ignore_multiplier);
}

function createFormatValTabulator(format_type){
    var funk = function(cell, formatterParams, onRendered) {
        var v = cell.getValue();
        var el = document.createElement("span");
        if(format_type !== FormatTypes.QUADRANT) {
            el.style.width = "100%";
            el.style.textAlign = "right";    
        } else {
            el.style.width = "100%";
            el.style.display = "flex";
            // el.style.justifyContent = "center";

        }
        el.innerHTML =formatValHTML(v, format_type);
        if(cell.getColumn().getDefinition().format_type === FormatTypes.TEXT) {
            var pre = document.createElement("div");
            pre.appendChild(el);
            return pre;
        }
        return el;
    };
    return funk;
}

function formatValHTMLOld(val, formatter){
    window._format.formatValHTMLOld(val, formatter);
}

export {Format, formatValHTML, formatValReact, formatValHTMLOld, formatValString, formatValNumber, createFormatValTabulator, formatLongestValString};